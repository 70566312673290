import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {resetPassword} from '../action'
import InputBW from '../components/InputBW'
import Alert from 'react-s-alert'

class ResetForgottenPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
		loading: false
    };
  }
  
  handleChange = e => {
    const {name, value} = e.target;
	this.setState({[name]: value});
  };

  componentDidMount() {
    this.getDocument();
  }

  getDocument = () => {
  };

  resetPassword = () => {
    const {id, hash} = this.props.match.params;
	let {password}=this.state;
    resetPassword(hash, password).then(res => {
        if (res.success) {
          this.props.history.replace('/login');
          Alert.success('Successfully changed password');
		  this.setState({state: res.state, password: ''})
          this.setState({loading: false});
        } else {
          Alert.error('Error.');
          this.setState({loading: false});
        }
    })
  };
 
  render() {
    const {html, state, loading} = this.state;
    let disabledStyle = {opacity: 1};

    return (
      <section className='inputPart'>
        <div className='text-center' style={disabledStyle}>
		 <div className='box'>
          <InputBW type='password'
                   label='Password'
                   placeholder='Enter New Password...'
                   name='password'
                   handleChange={this.handleChange}/>
         </div>
			<div className='btnRegister' onClick={this.resetPassword}>Send new password {loading && <i className='fa fa-spinner fa-pulse fa-fw'/>}
			</div>
        </div>

      </section>
    )
  }
}

export default withRouter(ResetForgottenPassword)
