import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import MenuCompanyBottom from '../../components/MenuCompanyBottom'

export default class Corporate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    return (
      <div>
        <section className='inputPart pbToSeeBottomMenu'>
          <div className='box'>

            <Link to='/corporate/1'>
              <button type='button' className='companyItemRow'>
                <div className='ddText'>
                  <p className='compBlockTitle '>SEND NOTICE TO SHAREHOLDERS' MEETING</p>
                  <p className='compBlockSubTitle'>Lähetä yhtiökokouskutsu</p>
                </div>
                <div className='ddSymbols undefined'>
                  <i className='fas fa-angle-right iconAngR'/>
                </div>
              </button>
            </Link>
            <Link to='/confirmation'>
              <button type='button' className='companyItemRow'>
                <div className='ddText'>
                  <p className='compBlockTitle '>Board/Auditor Confirmation for Share Issue</p>
                  <p className='compBlockSubTitle'>Hallituksen/tilintarkastajan vakuutus osakeantiin liittyen</p>
                </div>
                <div className='ddSymbols undefined'>
                  <i className='fas fa-angle-right iconAngR'/>
                </div>
              </button>
            </Link>

          </div>
          <MenuCompanyBottom/>
        </section>
      </div>
    )
  }
}
