import React, {Component} from 'react'

export default class ButtonDropdownB extends Component {
  render() {
    const {title, subtitle, doOnClick, arrowActive, smDropdownLabel, errSubmit, warning} = this.props;
    return (
      <div>
        <button type='button' className='companyItemRow companyInfoRowStyle' onClick={() => doOnClick()}>
          <div className='ddText'>
            <p className={`compInfoTitle ${smDropdownLabel ? smDropdownLabel : ''} `}>{title}</p>
            <p className='compInfoSubTitle'>{subtitle}</p>
          </div>
          <div className='ddSymbols'>
            {arrowActive ? <i className='fas fa-angle-up iconAngUW'/> : <i className='fas fa-angle-right iconAngRW'/>}
          </div>
        </button>
        {errSubmit && warning && <p className={`inputWarning`}>{warning}</p>}
      </div>
    )
  }
}
