import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import InputBW from '../../components/InputBW'
import {auth} from '../../action'
import {saveState} from '../../storage'
import Alert from 'react-s-alert'

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      password: '',
      errors: {}
    };
  }

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value}, () => this.validate())
  };

  componentDidMount() {
    this.validate();
  }

  validate = () => {
    let errors = {};
    let {name, email, phone, password} = this.state;

    if (!name || name.length < 2) {
      errors.name = 'Field Name is required!';
    }
    if (!email || email.length < 3) {
      errors.email = 'Field Email is required!';
    }
    if (!phone || phone.length < 8) {
      errors.phone = 'Field Phone is required!';
    }
    if (!password || password.length < 8) {
      errors.password = 'Choose password no less than 8 characters';
    }

    this.setState({errors: errors})
  };


  onSubmit = () => {
    let {errors, name, phone, email, password} = this.state;
    let countErrs = Object.keys(errors);
    if (countErrs.length === 0) {

    auth({action: 'signup', body: {name: name, email: email, phone: phone, password: password}}).then(res => {
      if (res.item) {
        saveState(res.item);
        this.props.history.push('/')
      }
	  else
	  {
		this.setState({errSubmit: true})
		errors.submit = res.errorMsg;		  
		saveState({errors});
		Alert.error(errors.submit);
	  }
    });
	
    } else {
      this.setState({errSubmit: true})
    }
  };

  render() {
    let {name, email, phone, password, errSubmit, errors} = this.state;
	const redStyle={color:'red'};
    return (
      <section className='inputPartFirst'>
        <div className='box'>
          <InputBW label='Name'
                   placeholder='Enter Full Name...'
                   name='name'
                   value={name}
                   errSubmit={errSubmit}
                   warning={errors.name}
                   handleChange={this.handleChange}/>
          <InputBW label='E-mail'
                   placeholder='Enter E-mail...'
                   name='email'
                   value={email}
                   errSubmit={errSubmit}
                   warning={errors.email}
                   handleChange={this.handleChange}/> 
			<InputBW label='Phone'
                   placeholder='Enter phonenumber...'
                   name='phone'
                   value={phone}
                   errSubmit={errSubmit}
                   warning={errors.phone}
                   handleChange={this.handleChange}/>
          <InputBW type='password'
                   label='Password'
                   placeholder='Enter Password...'
                   name='password'
                   value={password}
                   errSubmit={errSubmit}
                   warning={errors.password}
                   handleChange={this.handleChange}/>
        </div>
        <div className='text-center'>
          <div className=' btnRegister bottomFixedBtn' onClick={this.onSubmit}>Register</div>
        </div>
      </section>
    )
  }
}

export default withRouter(Registration)
