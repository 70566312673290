import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'

import DirectedIssueOfShares from './DirectedIssueOfShares'
import DirectedAcquisitionOfShares from './DirectedAcquisitionOfShares'
import ElectionOfTheChairman from './ElectionOfTheChairman'
import ConveneShareholdersMeeting from './ConveneShareholdersMeeting'
import OtherDecision from './OtherDecision'
import Procuration from './Procuration'
import ElectionOfBoardMembers from './ElectionOfBoardMembers'
import AnnualGeneralMeeting from './AnnualGeneralMeeting'
import AuthorizingBoard from './AuthorizingBoard'
import AmendingArticles from './AmendingArticles'

const DECISIONS = {
  1: <DirectedIssueOfShares/>,
  2: <DirectedAcquisitionOfShares/>,
  3: <ElectionOfTheChairman/>,
  4: <ConveneShareholdersMeeting/>,
  5: <OtherDecision/>,
  6: <AuthorizingBoard/>,
  7: <ElectionOfBoardMembers/>,
  8: <AmendingArticles/>,
  9: <AnnualGeneralMeeting/>,
  14: <Procuration/>,
};

class Decision extends Component {
  render() {
    return DECISIONS[this.props.match.params.id]
  }
}

export default withRouter(Decision)
