import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'

class Help extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className=''>
	  <div className='box' style={{color: "black"}}>
	  <br/>
	  </div>
	  <div className='box' style={{color: "black", paddingTop: "100px"}}>
<h1>
Welcome to use PocketInc!
</h1>
<p>
With PocketInc you can easily manage corporate decisions and documentation of your company. The documents you create with PocketInc comply with applicable corporate law. At the moment the service has only limited functionality and it supports only Finnish law.
</p>
<h2>
1. Overview
</h2>
<p>
Pending Signatures: shows those documents which are pending signatures. In connection with each document, you can see emails of those who have signed and those who have not signed (at brackets).
</p>
<p>
Documents Signed: archive for signed documents. You can open each document and download a local copy of the document.
</p>
<h2>
2. Settings
</h2>
<p>
Company Information: you can manage the general information about your company like the company name, shareholders (names and emails) and board members (names and emails).
</p>
<p>
Set User Password: manage your own password to the service.
</p>
<p>
Manage User Rights: all board members of your company can browse, create and manage the company information and documentation at the service. You can also provide third parties (e.g. your accountant or legal counsel) with similar rights.
</p>
<h2>
3. Board
</h2>
<p>
You can browse previous board meeting minutes and create new board meeting minutes. If the board convenes in a meeting, choose Meeting as the type of meeting. In this case the minutes will be sent for those board members for signing who are present at the meeting. If the board does not convene but only records certain decisions, choose Unanimous written decision as the type of meeting. In this case the minutes will be sent to all board members for signing.
</p>
<h2>
4. Shareholders
</h2>
<p>
You can browse previous shareholders meeting minutes and create new shareholders meeting minutes. If the shareholders convene in a meeting, choose Meeting as the type of meeting. In this case the minutes will be sent for the chairman and scrutinisers of the minutes. If the shareholders do not convene but only record certain decisions, choose Unanimous written decision as the type of meeting. In this case the minutes will be sent to all shareholders for signing.
</p>
<h2>
5. Corporate
</h2>
<p>
You can send notice to shareholders meetings to all shareholders. The notice will comply with the statutory requirements for the agenda items.
</p>
<h2>
6. Signatures
</h2>
<p>
The minutes will be electronically signed by the recipients identified by their unique email addresses. The signature is not based on strong customer authentication, but that is not required under the Finnish law.
</p>
<h2>
7. Privacy Policy
</h2>
<p>
Only the names and emails of the board members, shareholders and any third parties you designate the right to access your company information will be stored and processed in the service. The processing is done for the purpose of delivering the service. Such information is not used for any other purposes. 
</p>
<h2>
8. Contact 
</h2>
<p>
Please send any questions relating to the service to admin@pocketinc.fi.
</p>
<p>
(c) PocketInc Oy, 2021
</p>
      </div>
      </section>
    )
  }
}

export default withRouter(Help)
