import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css'

import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import Alert from 'react-s-alert'

import {loadState} from './storage'

import Header from './common/Header'
import Footer from './common/Footer'

import Authorization from './pages/auth/Authorization'
import Login from './pages/auth/Login'
import Registration from './pages/auth/Registration'

import ForgotPassword from './pages/ForgotPassword'
import ResetForgottenPassword from './pages/ResetForgottenPassword'
import AddServiceUser from './pages/AddServiceUser'

import Overview from './pages/menu/Overview'
import Board from './pages/menu/Board'
import Shareholders from './pages/menu/Shareholders'
import Corporate from './pages/menu/Corporate'

import CompanyInfo from './pages/company/CompanyInfo'
import ChangeCompany from './pages/company/ChangeCompany'

import ShareholdersMeeting from './pages/meeting/Shareholders'
import BoardMeeting from './pages/meeting/Board'

import Decision from './pages/decision/Decision'

import NoticeToShareholdersMeeting from './pages/actions/NoticeToShareholdersMeeting'
import ConfirmationForShareIssue from './pages/actions/ConfirmationForShareIssue'

import Settings from './pages/Settings'
import SettingsPassword from './pages/SettingsPassword'
import ManageUserRights from './pages/ManageUserRights'
import Help from './pages/Help'

import DocumentView from './pages/DocumentView'
import DocumentViewDraft from './pages/DocumentViewDraft'

import OpenDocument from './pages/OpenDocument'

const PrivateRoute = ({component: Component, ...rest}) => {
  const state = loadState();
  let company = null;
  if (state) {
    company = state.company;
  }
  // console.log(company);
  
  if (state && company) {
    return <Route {...rest} render={props => <Component {...props}/>}/>
  } else if (state && !company) {
    if (rest.path === '/company/info/:new?') {
      return <Route {...rest} render={props => <CompanyInfo {...props} lock={true}/>}/>
    } else {
      return <Route {...rest} render={props => <ChangeCompany {...props}/>}/>
    }
  } else {
    return <Route {...rest} render={props => <Redirect to='/'/>}/>
  }
};

const App = () => {
  if (!sessionStorage.getItem('logged'))
	  window.localStorage.clear();
  const state = loadState();
  const logged_in = state && state.auth_token;

  return (
    <div>

      <Header/>

      <Alert stack={{limit: 3}} timeout={5000} position={'top-right'}/>

      <Switch>

        {logged_in ? <PrivateRoute exact path='/' component={Overview}/> :
        <Route exact path='/' component={Authorization}/>}

        <Route exact path='/registration' component={Registration}/>
        <Route exact path='/login' component={Login}/>
        <Route exact path='/doc/:hash' component={OpenDocument}/>
		
        <Route exact path='/forgotPassword' component={ForgotPassword}/>
        <Route exact path='/tok/:hash' component={ResetForgottenPassword}/>

        <PrivateRoute exact path='/settings' component={Settings}/>
        <PrivateRoute exact path='/settings/password' component={SettingsPassword}/>
        <PrivateRoute exact path='/settings/addserviceuser' component={AddServiceUser}/>
        <PrivateRoute exact path='/settings/manageuserrights' component={ManageUserRights}/>
        <PrivateRoute exact path='/settings/help' component={Help}/>

        <PrivateRoute exact path='/board' component={Board}/>
        <PrivateRoute exact path='/shareholders' component={Shareholders}/>
        <PrivateRoute exact path='/overview' component={Overview}/>
        <PrivateRoute exact path='/corporate' component={Corporate}/>

        <PrivateRoute exact path='/corporate/1' component={NoticeToShareholdersMeeting}/>
        <PrivateRoute exact path='/confirmation' component={ConfirmationForShareIssue}/>
        <PrivateRoute exact path='/confirmation/:did' component={ConfirmationForShareIssue}/>

        <PrivateRoute exact path='/select/company' component={ChangeCompany}/>
        <PrivateRoute exact path='/company/info/:new?' component={CompanyInfo}/>

        <PrivateRoute exact path='/meeting/board' component={BoardMeeting}/>
        <PrivateRoute exact path='/meeting/board/:did' component={BoardMeeting}/>
        <PrivateRoute exact path='/meeting/shareholders' component={ShareholdersMeeting}/>
        <PrivateRoute exact path='/meeting/shareholders/:did' component={ShareholdersMeeting}/>

        <PrivateRoute exact path='/decision/issue/:idx/:id/:ret' component={Decision}/>
        <PrivateRoute exact path='/decision/issue/:idx/:id' component={Decision}/>
        <PrivateRoute exact path='/document/:id' component={DocumentView}/>
        <PrivateRoute exact path='/draftdoc/:id' component={DocumentViewDraft}/>

        <Redirect to='/'/>
      </Switch>

      <Footer/>
    </div>
  )
};

export default App

