import React, {Component} from 'react'

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='fixed-bottom bg-light p-3 text-muted text-center'>
        {/*<div>&copy; 2018 Def.to, All Rights Reserved</div>*/}
      </div>
    )
  }
}