import React, {Component} from 'react'


export default class CircleRadioInput extends Component {

  render(){
    let{label,chooseOption,name,radioItem}=this.props;

    return(

        <div className='row radioBtnW' onClick={chooseOption}>
          {radioItem === name?
            <div className='col-xs-2'><img src={require("./../../assets/img/icons/Blue/Radio/Selected.png")} alt="check" className='emptyCircle'/></div>
            :
            <div className='col-xs-2'><img src={require("./../../assets/img/icons/Blue/Radio/noSelected.png")} alt="check"
                                             className='emptyCircle'/></div>
          }
          <div className='col-xs-10'>
          <p className={'fz14'}>{label}</p>
          </div>
        </div>
    )
  }


}
