import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import InputBW from '../../components/InputBW'
import {auth, get} from '../../action'
import {loadState, saveState} from '../../storage'
import Alert from 'react-s-alert'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {},
      loading: false
    };
  }

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value}, () => this.validate())
  };

  componentDidMount() {
    this.validate();
  }

  validate = () => {
    let errors = {};
    let {email, password} = this.state;
    if (!email || email.length < 3) {
      errors.email = 'Email is required';
    }
    if (!password || password.length < 8) {
      errors.password = 'Password too short';
    }
    this.setState({errors: errors})
  };


  onSubmit = () => {
    this.setState({loading: true});
    let {errors, email, password} = this.state;
    let countErrs = Object.keys(errors);
    if (countErrs.length === 0) {
      auth({action: 'signin', body: {email: email, password: password}}).then(res => {
        if (res.success) {

		  window.sessionStorage.setItem('logged', true)

          saveState(res.item);
          get({model: 'company', action: 'list'}).then(res => {
            let state = loadState();
            state.company = res.list[0] || null;
            saveState(state);
          });
          this.props.history.replace('/');
          Alert.success('Successfully logged in');
          this.setState({loading: false});
        } else {
          Alert.error('Wrong credentials');
          this.setState({loading: false});

        }
      })
    } else {
      this.setState({errSubmit: true, loading: false})
    }
  };

  onForgot = () => {
	  this.props.history.replace('/forgotPassword');
  };

  render() {
    let {email, password, errSubmit, errors, loading} = this.state;
    return (
      <section className='inputPartFirst'>
        <div className='box'>
          <InputBW label='Email'
                   placeholder='Enter email...'
                   name='email'
                   value={email}
                   errSubmit={errSubmit}
                   warning={errors.email}
                   handleChange={this.handleChange}/>
          <InputBW type='password'
                   label='Password'
                   placeholder='Enter Password...'
                   name='password'
                   value={password}
                   errSubmit={errSubmit}
                   warning={errors.password}
                   handleChange={this.handleChange}/>
        </div>
        <div className='text-center'>
			<a className='learnMoreLink' onClick={this.onForgot}><i className='far fa-question-circle'/>No password or forgot password? Create or reset password here.</a>
			<br/>
			<br/>
        </div>
        <div className='text-center'>
          <div className='btnRegister' onClick={this.onSubmit}>Login {loading && <i className='fa fa-spinner fa-pulse fa-fw'/>}</div>
        </div>
      </section>
    )
  }
}

export default withRouter(Login)
