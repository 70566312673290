import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'

import InputBW from '../../components/InputBW'
import Datepicker from '../../components/Datepicker'
import TextareaBW from '../../components/TextareaBW'

import {loadForm, saveForm} from '../../storage'

class ConveneShareholdersMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      type: 'annual',
      date: new Date(),
      time: new Date(),
      location: '',
      standardAgenda: false,
      issueOfShares: false,
      redemptionOfShares: false,
      changeOfArticle: false,
      electingNewBoardMembers: false,
      shadow: false,
    }
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    const {idx, id} = this.props.match.params;
    const form = loadForm();
    if (form.decisions && form.decisions[idx]) {
      this.setState(form.decisions[idx].content)
    }
  }

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value}, () => this.validate())
  };

  handleChangeDate = name => date => {
    this.setState({[name]: date});
  };

  clickOutside = () => {
    this.picker.cancelFocusInput();
    this.picker.setOpen(false);
  };

  componentDidMount() {
    this.validate();
  }

  validate = () => {
    let errors = {};
    let {} = this.state;
    this.setState({errors: errors})
  };

  clearField = name => {
    this.setState({[name]: ''})
  };

  chooseOption = (fieldName, name) => {
    this.setState({[fieldName]: name});
  };

  saveDecision = () => {
    const {history, match} = this.props;
    const {idx, id,ret} = match.params;
    let {errors} = this.state;
    let countErrs = Object.keys(errors);
    if (countErrs.length === 0) {
      let form = loadForm();
      if (!form.decisions) {
        form.decisions = [{id: id, content: this.state}];
      } else {
        form.decisions[idx] = {id: id, content: this.state};
      }
      saveForm(form);
	  if (ret=='board')
		this.props.history.replace('/meeting/board');
	  else
		this.props.history.replace('/meeting/shareholders');

    } else {
      this.setState({errSubmit: true})
    }
  };

  render() {
    let {
      openPopup = [],
      errSubmit,
      errors,
      popUp,
      type,
      date,
      time,
      location,
      standardAgenda,
      issueOfShares,
      redemptionOfShares,
      changeOfArticle,
      changesToArticle,
      electingNewBoardMembers,
      shadow
    } = this.state;

    return (
      <div>
        <section className='inputPart pbToSeeBottomMenu'>

          <div className='box'>
            <p className='addText'>Select Type of Meeting</p>
            <div className='metingTypeBlock'>
              <div className={`metingType  ${type === 'annual' ? 'metingTypeSelected' : 'pointer'}`}
                   onClick={() => this.setState({type: 'annual'})}>Annual General Meeting
              </div>
              <div className={`metingType  ${type === 'extraordinary' ? 'metingTypeSelected' : 'pointer'}`}
                   onClick={() => this.setState({type: 'extraordinary'})}>Extraordinary Shareholders' Meeting
              </div>
            </div>
          </div>


          <div className='box'>
            <p className='blockTitle addTextMB mt-28'>Date, Time and Venue of Meeting</p>

            <div className='halfInputBW'>
              <Datepicker calendarStyle='calendarStylePurple'
                          label='Date'
                          smallLabel='smallLabelSizeBW'
                          chooseDate={this.handleChangeDate('date')}
                          value={new Date(date)}/>
            </div>

            <div className='halfInputBW2'>
              <Datepicker calendarStyle='calendarStylePurple'
                          ref={el => this.picker = el}
                          timeStyle='timeStylePurple'
                          label='Time'
                          type='time'
                          smallLabel='smallLabelSizeBW'
                          chooseDate={this.handleChangeDate('time')}
                          value={new Date(time)}/>
            </div>

            <InputBW type='text'
                     label='Location'
                     placeholder='Enter Location Here'
                     name='location'
                     value={location}
                     handleChange={this.handleChange}
                     errSubmit={errSubmit}
                     warning={errors.location}
                     doOnTimes={this.clearField}
                     issueStyle='issueStyle'
                     smallLabel='smallLabelSizeBW'
                     smallBtnDelete='deleteONSmallINput'/>
          </div>

          <div className='box'>
            <p className='blockTitle addTextMB additionalTextSmMT'>Annual General Meeting</p>
            <div className='selectedBox'>
              <label className='checkboxContainer mt-6 ml-6'>
                <input type='checkbox' checked={standardAgenda} value={standardAgenda}
                       onChange={() => this.setState({standardAgenda: !standardAgenda})}/>
                <span className='checkmark'/>
              </label>
              <div className='selectedBoxData'>
                <h5>Standard Agenda</h5>
                <p>For Annual General Shareholders' Meeting</p>
              </div>
            </div>
          </div>

          <div className='box'>
            {/*<p className='blockTitle addTextMB mt-28'>Shares</p>*/}
            <div className='selectedBox'>
              <label className='checkboxContainer mt-6 ml-6'>
                <input type='checkbox' checked={issueOfShares} value={issueOfShares}
                       onChange={() => this.setState({issueOfShares: !issueOfShares})}/>
                <span className='checkmark'/>
              </label>
              <div className='selectedBoxData'>
                <h5>Issue of Shares</h5>
                <p>Authorization for Board to Issue Shares</p>
              </div>
            </div>

            <div className='selectedBox'>
              <label className='checkboxContainer mt-6 ml-6'>
                <input type='checkbox' checked={redemptionOfShares} value={redemptionOfShares}
                       onChange={() => this.setState({redemptionOfShares: !redemptionOfShares})}/>
                <span className='checkmark'/>
              </label>
              <div className='selectedBoxData'>
                <h5>Redemption of Shares</h5>
                <p>Authorization for Board to Redeem Shares</p>
              </div>
            </div>

            <div className='selectedBox'>
              <label className='checkboxContainer mt-6 ml-6'>
                <input type='checkbox' checked={electingNewBoardMembers} value={electingNewBoardMembers}
                       onChange={() => this.setState({electingNewBoardMembers: !electingNewBoardMembers})}/>
                <span className='checkmark'/>
              </label>
              <div className='selectedBoxData'>
                <h5>Electing new Board Member(s)</h5>
                <p></p>
              </div>
            </div>

          </div>

          <div className='box'>
            {/*<p className='blockTitle addTextMB mt-28'>Change of Articles</p>*/}
            <div className='selectedBox'>
              <label className='checkboxContainer mt-6 ml-6'>
                <input type='checkbox' checked={changeOfArticle} value={changeOfArticle}
                       onChange={() => this.setState({changeOfArticle: !changeOfArticle})}/>
                <span className='checkmark'/>
              </label>
              <div className='selectedBoxData'>
                <h5>Changes of Articles</h5>
                <p>Insert changes to Articles in the next field</p>
              </div>
            </div>

            <TextareaBW label='Changes to Article'
                        placeholder='Enter changes Here...'
                        name='changesToArticle'
                        value={changesToArticle}
                        handleChange={this.handleChange}
                        errSubmit={errSubmit}
                        warning={errors.changesToArticle}
                        smallLabel='smallLabelSizeBW'/>
          </div>


          <div className='text-center'>
            <div className=' btnRegister bottomFixedBtn' onClick={this.saveDecision}>
              <i className='far fa-check-circle circle'/>Save Decision
            </div>
          </div>

        </section>
      </div>
    )
  }

}

export default withRouter(ConveneShareholdersMeeting)
