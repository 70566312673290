import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import InputBW from '../../components/InputBW'
import ButtonDropdownB from '../../components/ButtonDropdownB'
import Popup from '../../components/Popup'
import ShareClass from '../../components/ShareClass'
import Datepicker from '../../components/Datepicker'
import TextareaBW from '../../components/TextareaBW'
import {loadForm, saveForm} from '../../storage'

class DirectedIssueOfShares extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      subscriptionEndDate: new Date(),
      authorizationDate: new Date(),
      paymentDueDate: new Date()
    }
  }

  componentWillMount() {
    const {idx, id} = this.props.match.params;
    const form = loadForm();
    if (form.decisions && form.decisions[idx]) {
      this.setState(form.decisions[idx].content)
    }
  }

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value}, () => this.validate())
  };

  handleChangeDate = name => date => {
    this.setState({[name]: date});
  };

  componentDidMount() {
    this.validate();
  }

  validate = () => {
    let errors = {};
    let {numberOfShares_1, subscriptionPricePerShare, decision, shareClass_1, shareClass_2} = this.state;

    if (decision && decision.length>0) {
      this.setState({errors: errors});

      return
    }

    if (!numberOfShares_1) {
      errors.numberOfShares_1 = 'Required!';
    }

    if (!subscriptionPricePerShare) {
      errors.subscriptionPricePerShare = 'Required!';
    }

    if (!shareClass_1) {
      errors.shareClass_1 = 'Required!';
    }

    if (!shareClass_2) {
      errors.shareClass_2 = 'Required!';
    }

    this.setState({errors: errors})
  };

  clearField = name => {
    this.setState({[name]: ''})
  };

  showPopup = (i, type) => {
    let {openPopup = [], popUp = {}} = this.state;
    popUp.status = !popUp.status;
    popUp.type = type;
    if (i) {
      openPopup[i] = !openPopup[i];
    } else {
      openPopup = [];
    }
    window.scrollTo(0, 0);
    this.setState({openPopup: openPopup, popUp: popUp});
  };


  chooseOption = (fieldName, name) => {
    this.setState({[fieldName]: name}, () => this.validate());
  };

  saveDecision = () => {
    const {history, match} = this.props;
    const {idx, id, ret} = match.params;
    let {errors} = this.state;

    // console.log('errors: ', errors);

    let countErrs = Object.keys(errors);
    if (countErrs.length === 0) {
      let form = loadForm();
      if (!form.decisions) {
        form.decisions = [{id: id, content: this.state}];
      } else {
        form.decisions[idx] = {id: id, content: this.state};
      }
      saveForm(form);
	  if (ret=='board')
		this.props.history.replace('/meeting/board');
	  else
		this.props.history.replace('/meeting/shareholders');

    } else {
      this.setState({errSubmit: true})
    }
  };

  render() {
    const {history, match} = this.props;
    const {ret} = match.params;

    let {
      openPopup = [],
      errSubmit,
      errors,
      popUp,

      authorizationDate,
	  
      shareClass_1,
      numberOfShares_1,

      shareClass_2,
      numberOfShares_2,

      reason,

      subscriptionSubscribers,
      subscriptionValuationMethod,
      subscriptionPricePerShare,
      subscriptionEndDate,

      paymentDueDate,
      paymentRecording,

      decision

    } = this.state;
    return (
      <div>
        <div className={`shade ${popUp && popUp.status ? 'shadeActive' : ' '}`}/>
        <section className='inputPart pbToSeeBottomMenu'>
          {popUp && popUp.status && (popUp.type === 'issue')
            ? <Popup title='Share Class' subtitle='Osakelaji'
                     popupStatus={popUp.status}
                     closePopup={() => this.showPopup()}
                     doOnTimes={this.clearField}
                     popupSize='popUpBoxWidth'>
              <ShareClass radioItem={shareClass_1}
                          list='shareclass'
                          type='shareClass_1'
                          chooseOption={this.chooseOption}
                          stateName='shareClass_1'/>
            </Popup>
            : null
          }

          {popUp && popUp.status && (popUp.type === 'existingIssue')
            ? <Popup title='Share Class' subtitle='Osakelaji'
                     popupStatus={popUp.status}
                     closePopup={() => this.showPopup()}
                     doOnTimes={this.clearField}
                     popupSize='popUpBoxWidth'>
              <ShareClass radioItem={shareClass_2}
                          list='shareclass'
                          type='shareClass_2'
                          chooseOption={this.chooseOption}
                          stateName='shareClass_2'/>
            </Popup>
            : null
          }

          {popUp && popUp.status && (popUp.type === 'reasoning')
            ? <Popup title='Reason for Directed Issue'
                     subtitle='Peruste osakeannin suuntaamiselle'
                     popupStatus={popUp.status}
                     closePopup={() => this.showPopup()}
                     doOnTimes={this.clearField}
                     popupSize='popUpBoxWidth'>
              <ShareClass radioItem={reason}
                          list='reason'
                          type='reason'
                          chooseOption={this.chooseOption}
                          stateName='reason'/>
            </Popup>
            : null
          }

          {popUp && popUp.status && (popUp.type === 'subscriptionSubscribers')
            ? <Popup title='Subscribers'
                     subtitle='Merkitsijät'
                     popupStatus={popUp.status}
                     closePopup={() => this.showPopup()}
                     doOnTimes={this.clearField}
                     popupSize='popUpBoxWidth'>
              <ShareClass radioItem={subscriptionSubscribers}
                          list='subscriber'
                          type='subscriptionSubscribers'
                          chooseOption={this.chooseOption}
                          stateName='subscriptionSubscribers'/>
            </Popup>
            : null
          }

          {popUp && popUp.status && (popUp.type === 'subscriptionValuationMethod')
            ? <Popup title='Valuation Method'
                     subtitle='Arvonmääritys'
                     popupStatus={popUp.status}
                     closePopup={() => this.showPopup()}
                     doOnTimes={this.clearField}
                     popupSize='popUpBoxWidth'>
              <ShareClass radioItem={subscriptionValuationMethod}
                          list='valuation'
                          type='subscriptionValuationMethod'
                          chooseOption={this.chooseOption}
                          stateName='subscriptionValuationMethod'/>
            </Popup>
            : null
          }

          {popUp && popUp.status && (popUp.type === 'paymentRecording')
            ? <Popup title='Recording Payment In'
                     subtitle='Merkintähinnan merkintä omaan pääomaan'
                     popupStatus={popUp.status}
                     closePopup={() => this.showPopup()}
                     doOnTimes={this.clearField}
                     popupSize='popUpBoxWidth'>
              <ShareClass radioItem={paymentRecording}
                          list='payment'
                          type='paymentRecording'
                          chooseOption={this.chooseOption}
                          stateName='paymentRecording'/>
            </Popup>
            : null
          }
		  
		  {ret=='board' ?
          <div className='box'>
            <p className='blockTitle addTextMB additionalTextSmMT'>Date Of Authorization</p>
            <div className='smallInputBW'>
              <Datepicker calendarStyle='calendarStylePurple'
                          label='Date Of Authorization'
                          smallLabel='smallLabelSizeBW'
                          chooseDate={this.handleChangeDate('authorizationDate')}
                          value={new Date(authorizationDate)}/>
            </div>
          </div>
		  : null }

          <div className='box'>
            <p className='blockTitle addTextMB '>New Shares to be Issued</p>
            <div className='smallInputBW'>
              <InputBW type='number'
                       label='Number of Shares'
                       name='numberOfShares_1'
                       placeholder='0'
                       value={numberOfShares_1}
                       handleChange={this.handleChange}
                       errSubmit={errSubmit}
                       warning={errors.numberOfShares_1}
                       doOnTimes={this.clearField}
                       issueStyle='issueStyle'
                       numberStyle='numberField'
                       smallLabel='smallLabelSizeBW'
                       smallBtnDelete='deleteONSmallINput'/>
            </div>

            <div className='smallDropdownB'>
              <ButtonDropdownB title='Share class'
                               subtitle={shareClass_1 && shareClass_1.label || 'Select Class'}
                               doOnClick={() => this.showPopup(1, 'issue')}
                               arrowActive={openPopup[1]}
                               smDropdownLabel='smallLabelSizeDd'
                               errSubmit={errSubmit}
                               warning={errors.shareClass_1}/>
            </div>
          </div>

          <div className='box'>
            <p className='blockTitle addTextMB additionalTextSmMT'>Existing Shares to be Issued</p>
            <div className='smallInputBW'>
              <InputBW type='number'
                       label='Number of Shares'
                       name='numberOfShares_2'
                       placeholder='0'
                       value={numberOfShares_2}
                       handleChange={this.handleChange}
                       // errSubmit={errSubmit}
                       // warning={errors.numberOfShares_2}
                       doOnTimes={this.clearField}
                       issueStyle='issueStyle'
                       numberStyle='numberField'
                       smallLabel='smallLabelSizeBW'
                       smallBtnDelete='deleteONSmallINput'/>
            </div>

            <div className='smallDropdownB'>
              <ButtonDropdownB title='Share class'
                               subtitle={shareClass_2 && shareClass_2.label || 'Select Class'}
                               doOnClick={() => this.showPopup(2, 'existingIssue')}
                               arrowActive={openPopup[2]}
                               smDropdownLabel='smallLabelSizeDd'
                               errSubmit={errSubmit}
                               warning={errors.shareClass_2}/>
            </div>
          </div>


          <div className='box'>
            <p className='blockTitle addTextMB additionalTextSmMT'>Reasons for directed issue</p>
            <ButtonDropdownB title='Reason for directed issue'
                             subtitle={reason && reason.label || 'Select the Reason'}
                             doOnClick={() => this.showPopup(3, 'reasoning')}
                             arrowActive={openPopup[3]}
                             smDropdownLabel='smallLabelSizeDd'/>
          </div>

          <div className='box'>
            <p className='blockTitle addTextMB additionalTextSmMT'>Subscription</p>

            <div className='smallInputBW'>
              <Datepicker calendarStyle='calendarStylePurple'
                          label='Period end date'
                          smallLabel='smallLabelSizeBW'
                          chooseDate={this.handleChangeDate('subscriptionEndDate')}
                          value={new Date(subscriptionEndDate)}/>
            </div>

            <div className='smallDropdownB'>
              <ButtonDropdownB title='Subscribers'
                               subtitle={subscriptionSubscribers && subscriptionSubscribers.label || 'Specify subscribers'}
                               doOnClick={() => this.showPopup(4, 'subscriptionSubscribers')}
                               arrowActive={openPopup[4]}
                               smDropdownLabel='smallLabelSizeDd'/>
            </div>
          </div>

          <div className='box'>
            <div className='smallInputBW'>
              <InputBW type='number'
                       label='Price per share (Є)'
                       name='subscriptionPricePerShare'
                       placeholder='0'
                       value={subscriptionPricePerShare}
                       handleChange={this.handleChange}
                       errSubmit={errSubmit}
                       warning={errors.subscriptionPricePerShare}
                       doOnTimes={this.clearField}
                       issueStyle='issueStyle'
                       numberStyle='numberField'
                       smallLabel='smallLabelSizeBW'
                       smallBtnDelete='deleteONSmallINput'/>
            </div>

            <div className='smallDropdownB'>
              <ButtonDropdownB title='Valuation method'
                               subtitle={subscriptionValuationMethod && subscriptionValuationMethod.label || 'Select Method'}
                               doOnClick={() => this.showPopup(5, 'subscriptionValuationMethod')}
                               arrowActive={openPopup[5]}
                               smDropdownLabel='smallLabelSizeDd'/>
            </div>
          </div>

          <div className='box'>
            <p className='blockTitle addTextMB additionalTextSmMT'>Payment</p>

            <div className='smallInputBW'>
              <Datepicker calendarStyle='calendarStylePurple'
                          label='Due date'
                          smallLabel='smallLabelSizeBW'
                          chooseDate={this.handleChangeDate('paymentDueDate')}
                          value={new Date(paymentDueDate)}/>
            </div>

            <div className='smallDropdownB'>
              <ButtonDropdownB title='Recording Payment In'
                               subtitle={paymentRecording && paymentRecording.label || 'Select Reason'}
                               doOnClick={() => this.showPopup(6, 'paymentRecording')}
                               arrowActive={openPopup[6]}
                               smDropdownLabel='smallLabelSizeDd'/>
            </div>
          </div>


          <div className='box'>
            <p className='blockTitle addTextMB additionalTextSmMT'>Tailored Decision</p>
            <TextareaBW label='Tailored Decision'
                        placeholder='Enter decision Here...'
                        name='decision'
                        value={decision}
                        handleChange={this.handleChange}
              // errSubmit={errSubmit}
              // warning={errors.decision}
                        smallLabel='smallLabelSizeBW'/>
          </div>

          <div className='text-center'>
            <div className=' btnRegister bottomFixedBtn' onClick={this.saveDecision}>
              <i className='far fa-check-circle circle'/>Save Decision
            </div>
          </div>

        </section>
      </div>
    )
  }

}

export default withRouter(DirectedIssueOfShares)
