import React, {Component} from 'react'
import DatePicker, {CalendarContainer} from 'react-datepicker'

export default class Datepicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  close = () => {
    this.picker.cancelFocusInput();
    this.picker.setOpen(false);
  };

  render() {
    let {calendarStyle, timeStyle, label, smallLabel, chooseDate, value, type = 'date'} = this.props;

    const time = {
      selected: value,
      onChange: chooseDate,
      showTimeSelect: true,
      showTimeSelectOnly: true,
      dateFormat: 'h:mm',
      timeCaption: 'Time',
      timeIntervals: 5,
      calendarContainer: props => MyContainer({
        className: props.className,
        children: props.children,
        handleClose: this.close
      }),
      withPortal: true
    };

    const date = {
      selected: value,
      onChange: chooseDate,
      dateFormatCalendar: 'MMMM',
      showYearDropdown: true,
      scrollableYearDropdown: true,
      yearDropdownItemNumber: 15,
      calendarContainer: props => MyContainer({
        className: props.className,
        children: props.children,
        handleClose: this.close
      }),
      withPortal: true
    };

    const inProps = type === 'date' ? date : time;
    return (
      <div
        className={timeStyle ? (calendarStyle ? calendarStyle + ' ' + timeStyle : ' ') : (calendarStyle ? calendarStyle : ' ')}>
        <label className={`fieldLabel ${smallLabel ? smallLabel : ''}`}>{label}</label>
        <br/>
        <DatePicker {...inProps} ref={el => this.picker = el}/>
      </div>
    )
  }
}


function MyContainer({className, children, handleClose}) {

  let timeLabel = className.includes('react-datepicker--time-only');

  return (
    <div className={'animated fadeInUp mobWidthCalendar shadeActive'}>
      <CalendarContainer className={className}>
        <i className='fas fa-angle-down btnClosePopup calendarPadT' onClick={handleClose}/>
        <h1 className='popupTitleMain colorWhite mobTitleCalendar'>{!timeLabel ? 'Calendar' : 'Time'}</h1>
        <h3 className='popupSubtitleMain colorWhite mobTitleCalendar'>{!timeLabel ? 'Kalenteri' : 'Aika'}</h3>
        <div className={!timeLabel ? 'calendarBlock' : 'timeBlock'}>
          {children}
        </div>
        <div className='scrollInstruction'>{timeLabel ? 'Scroll to bottom' : null}</div>
      </CalendarContainer>
    </div>
  );
}


