import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {getDraftDocument, getDocument, download} from '../action'
import {loadState} from '../storage'

class DocumentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      html: '',
      doc: null
    };
  }

  componentWillMount() {
    this.getDocument();
  }

  getDocument = () => {
    const {id} = this.props.match.params;

	const {company}=loadState();
	
    getDraftDocument(company.id, id).then(res => {
      this.setState({html: res.html, doc: res.item})
    })
  };

  sign = () => {

  };

  render() {
    const {html, doc} = this.state;
    return (
      <section className='inputPart'>
        <div className='box'>
          <div className='pdfLook pb-5 mb-5'>
            <div dangerouslySetInnerHTML={{__html: html}} style={{color: 'black'}}/>
          </div>
        </div>

      </section>
    )
  }
}

export default withRouter(DocumentView)
