import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'
import ButtonDropdownW from '../../components/ButtonDropdownW'
import {get,auth} from '../../action'
import {loadState, saveState, clearState} from '../../storage'

class ChangeCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: []
    }
  }

  componentWillMount() {
    this.getCompanies();
  }

  getCompanies = () => {
    get({model: 'company', action: 'list'}).then(res => {
      // console.log('res: ', res);
      this.setState({companies: res.list});
    })
  };

  selectCompany = company => {
    const {history} = this.props;
    let state = loadState();
    state.company = company;
    saveState(state);
    history.push('/overview')
  };
  
  logout = () => {
    const state = loadState();
    auth({action: 'logout', body: {id: state.id}}).then(res => {
      if (res.success) {
        clearState();
        this.props.history.replace('/');
      }
    })
  };

  renderCompanies = (item, i) => {
    return (
      <ButtonDropdownW title={item.name}
                       key={`comp-${i}`}
                       subtitle={null}
                       doOnClick={() => this.selectCompany(item)}
                       statusActive={null}/>
    )
  };

  render() {
    const {companies} = this.state;
    return (
      <div>
 		  {companies.length==0 &&
		  <div>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
                    <div className='btnLogout' onClick={this.logout}>Logout</div>
					</div>
           }
		<section className='inputPart pbToSeeBottomMenu'>
          <div className='box'>
            <Link to='/company/info/new'>
              <div className='btnAdd newMeetingBtn '><i className='fas fa-plus'/>Add company</div>
            </Link>
          </div>
          <div className='box'>
            {companies.map(this.renderCompanies)}
            {/*<p className='endListText'>- End of List -</p>*/}
          </div>

        </section>
      </div>
    )
  }
}

export default withRouter(ChangeCompany)
