import React, {Component} from 'react'
import TextareaBW from '../../components/TextareaBW'
import {loadForm, saveForm} from '../../storage'

export default class Procuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decision: '',
      errors: {}
    };
    window.scrollTo(0,0);
  }

  validate = () => {
    let errors = {};
    let {decision} = this.state;

    this.setState({errors: errors})
  };

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value}, () => this.validate())
  };

  saveDecision = () => {
    const {history, match} = this.props;
    const {idx, id,ret} = match.params;
    let {errors} = this.state;
    let countErrs = Object.keys(errors);
    if (countErrs.length === 0) {
      let form = loadForm();
      if (!form.decisions) {
        form.decisions = [{id: id, content: this.state}];
      } else {
        form.decisions[idx] = {id: id, content: this.state};
      }
      saveForm(form);
	  if (ret=='board')
		this.props.history.replace('/meeting/board');
	  else
		this.props.history.replace('/meeting/shareholders');

    } else {
      this.setState({errSubmit: true})
    }
  };

  render() {
    const {decision, errSubmit, errors} = this.state;
    return (
      <section className='inputPart pbToSeeBottomMenu'>
        <div className='box'>
          <TextareaBW label='ADD / REMOVE PROCURATION'
                      placeholder='Enter decision Here...'
                      name='decision'
                      value={decision}
                      handleChange={this.handleChange}
                      errSubmit={errSubmit}
                      warning={errors.decision}
                      rows={15}
                      smallLabel='smallLabelSizeBW'/>
        </div>

        <div className='text-center'>
          <div className=' btnRegister bottomFixedBtn' onClick={this.saveDecision}>
            <i className='far fa-check-circle circle'/>Save Decision
          </div>
        </div>
      </section>
    )
  }
}
