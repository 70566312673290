const STATE = 'state';
const FORM = 'form';

const load = type => {
  try {
    const serializedState = localStorage.getItem(type);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

const save = (state, type) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(type, serializedState);
  } catch (error) {
    console.error("State wasn't saved")
  }
};

const clear = type => {
  localStorage.removeItem(type);
};


export const loadState = () => load(STATE);

export const saveState = state => save(state, STATE);

export const clearState = () => clear(STATE);

export const loadForm = () => load(FORM);

export const saveForm = state => save(state, FORM);

export const clearForm = () => clear(FORM);
