import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import ButtonDropdownW from '../../components/ButtonDropdownW'
import SliderContainer from '../../components/SliderContainer'
import MenuCompanyBottom from '../../components/MenuCompanyBottom'
import {get} from '../../action'
import {loadState, clearForm} from '../../storage'

export default class Shareholders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSlider: [],
      data: []
    };
  }

  componentWillMount() {
    const {company} = loadState();
    get({model: 'decision', action: 'list', body: {company_id: company.id, type: 'shareholder'}}).then(res => {
      this.setState({data: res.list});
    })
  }

  showSlider = i => e => {
    let {openSlider} = this.state;
    openSlider[i] = !openSlider[i];
    this.setState({openSlider: openSlider});
  };

  renderButton = (year, i) => {
    const {openSlider, data} = this.state;
    return <div key={`d-${year}`}>
      <ButtonDropdownW
        title={year}
        titleCss='fsize-30'
        rightBoxCss='colorSB flexCenter'
        preCountText={'Total Meetings'}
        // subtitle='odottavat allekirjoitukset'
        count={data[year].length <= 9 ? '0' + data[year].length : data[year].length}
        doOnClick={this.showSlider(i)}
        statusActive={openSlider[i]}
      />
      {openSlider[i] && <SliderContainer sliderCompanyData={data[year]} k={i}/>}
    </div>
  };

  render() {
    const {data} = this.state;
    const years = Object.keys(data).sort((a, b) => b - a);

    return (
      <div>
        <section className='inputPart pbToSeeBottomMenu'>
          <div className='box'>
            {/*<Link to='/meeting/shareholders'>
            </Link>*/}
              <div className='btnAdd newMeetingBtn '
			  onClick={() => {clearForm() ; this.props.history.push('/meeting/shareholders');}}>
			  <i className='fas fa-plus'/>New meeting</div>
          </div>

          <div className='box'>
            <p className='welcomeText'>Document Archive</p>

            {years.map(this.renderButton)}

            {years.length < 1 &&
            <div className='companyItemRow noList'>
              <div className='ddText'><p className='compBlockTitle'>No decisions</p></div>
            </div>
            }

            {/*<p className='endListText'>- End of List -</p>*/}
          </div>

          <MenuCompanyBottom/>
        </section>
      </div>
    )
  }
}
