import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import InputBW from '../../components/InputBW'
import ButtonDropdownB from '../../components/ButtonDropdownB'
import {loadState, saveState} from '../../storage'
import {get} from '../../action'
import Popup from '../../components/Popup'
import Members from '../../components/Members'
import Alert from 'react-s-alert'

class CompanyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPopup: [],
      popUp: {},
      users: [],
      boards: [],
      shareholders: [],
      deputy: [],
      id: null,
      name: ''
    };
  }

  componentDidMount() {
    // this.getUserList();
    this.getUserList(this.state.users, 'board');
    this.getCompany();
	
	// console.log(this.state.users);
  }

  refresh = () => {
    this.getUserList(this.state.users);
    // this.getCompany();
  };

  getCompany = () => {
    const {params} = this.props.match;
    if (params.new) return;
    const {company} = loadState();
    if (company) {
      get({model: 'company', action: 'getById', body: {id: company.id}}).then(res => {
        const {id, name, boards, shareholders, deputy} = res.item;
        this.setState({id, name, boards, shareholders, deputy});
      })
    }
  };

  // getUserList = () => {
    // const {company} = loadState();
    // const {id} = company?company:false;
    // if (id) {
      // get({model: 'user', action: 'list', body: {company_id: company.id}}).then(res => {
        // this.setState({users: res.list});
      // })
    // }
  // };
  isIdInObject(id, users=[], type)
  {
	  let u=(Object.keys(users)).filter(key=>{
			    return((users[key]['id']==id)&&(users[key]['type']==type))
		  });
    // console.log(u);
	  return(u.length>0);
  }

  getUserList = (m = [], type='board') => {
    const {company} = loadState();
    let {users}=this.state;
	if (company && company.id)
	{
		get({model: 'user', action: 'listall', body: {company_id: company.id}}).then(res => {
		  if (users.length<1)
			  this.setState({users: res.list});
		  if (m && m.length>0) {
			  if (!users && users.length<1)
				users=[];
			   m.forEach(x=>{
				if (!this.isIdInObject(x.id, users, x.type))
					users.push(x);
			  });
			  this.setState({users: users});
			  // console.log(m);
			  // console.log(users);
		  }
		})
	}
  };

  save = () => {
    const {history} = this.props;
    const {id, boards, shareholders, deputy, name, users} = this.state;
    get({
      model: 'company',
      action: 'save',
      body: {id: id, name: name, users: users, boards: boards, shareholders: shareholders, deputy: deputy}
    }).then(res => {
      if (res.success) {
        let state = loadState();
        state.company = res.item;
        saveState(state);
        if (id) {
          history.push('/overview')
        } else {
          this.setState({id: res.item.id, name: res.item.name, users: [], boards: [], shareholders: [], deputy: []});
          history.replace('/company/info')
        }
      }
	  else
        Alert.error('Error. (company exists)');
    })
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  };

  showPopup = (i, type) => {
    let {openPopup, popUp = {}} = this.state;
    popUp.status = !popUp.status;
    popUp.type = type;
    if (i) {
      openPopup[i] = !openPopup[i];
    } else {
      openPopup = [];
    }
	window.scrollTo(0, 0);
    this.setState({openPopup: openPopup, popUp: popUp});
  };

  clearField = (name) => (e) => {
    this.setState({
      [name]: ''
    })
  };

  removeUser = u => {
    const {company} = loadState();
    const {id} = company;
    get({model: 'user', action: 'delete', body: {id: u.id, company_id: id}}).then(res => {
      this.getUserList();
      this.getCompany();
    });
  };

  renderUsers = u => {
    return (
      <div key={u.id} className='selectedBox'>
        <i className='fas fa-times-circle' onClick={() => this.removeUser(u)}/>
        <div className='selectedBoxData'>
          <h5>{u.name}</h5>
          <p>{u.email}</p>
        </div>
      </div>
    )
  };

  render() {
    const {lock = false} = this.props;
    let {id, openPopup, name, popUp, users, boards, shareholders, deputy} = this.state;

    const listShProps = {
      searchPlaceholder: 'Search Members',
      addBtnText: 'Add Shareholder'
    };

    const popupShProps = {
      title: 'Insert shareholders',
      subtitle: 'Osakkeenomistajat',
    };

    const listProps = {
      searchPlaceholder: 'Search Members',
      addBtnText: 'Add Ordinary Board Member'
    };

    const listProps2 = {
      searchPlaceholder: 'Search Members',
      addBtnText: 'Add Deputy Board Member'
    };

    const popupProps = {
      title: 'Ordinary Board Members',
      subtitle: 'Hallituksen jäsenet',
    };

    const popupProps2 = {
      title: 'Deputy Board Members',
      subtitle: 'Hallituksen varajäsenet',
    };

    return (
      <div>
        <div className={`shade ${popUp && popUp.status ? 'shadeActive' : ' '}`}/>
        <section className='inputPart specialCompInfoPadT'>
          <div className='box'>
            <InputBW label='Company Name' placeholder='Your Company Name' name='name' value={name}
                     handleChange={this.handleChange} doOnTimes={this.clearField}/>

            {!lock && id &&
            <div>

              {popUp && popUp.status && (popUp.type === 'shareholders')
                ? <Popup title={popupShProps.title} subtitle={popupShProps.subtitle} popupStatus={popUp.status}
                         closePopup={() => this.showPopup()}
                         doOnTimes={this.clearField}>
                  <Members
                    {...listShProps}
                    selected={shareholders}
                    selectAction={m => this.setState({shareholders: m})}
                    users={users}
                    onUpdate={(m) => this.getUserList(m, 'shareholder')}
                    refresh={this.refresh}
					btnAddToForm={true}
                    type='shareholder'/>
                </Popup>
                : null}

              <ButtonDropdownB
                title='Shareholders'
                subtitle='Insert shareholders'
                doOnClick={() => this.showPopup(1, 'shareholders')}
                arrowActive={openPopup[1]}/>

              {users.map(u => {
                if (shareholders.includes(u.id)) {
                  return this.renderUsers(u)
                }
              })}

              {popUp && popUp.status && (popUp.type === 'boards')
                ? <Popup title={popupProps.title} subtitle={popupProps.subtitle} popupStatus={popUp.status}
                         closePopup={() => this.showPopup()}
                         doOnTimes={this.clearField}>
                  <Members
                    {...listProps}
                    selected={boards}
                    selectAction={m => this.setState({boards: m})}
                    users={users}
                    onUpdate={(m) => this.getUserList(m, 'board')}
                    refresh={this.refresh}
					btnAddToForm={true}
                    type='board'/>
                </Popup>
                : null}

              <ButtonDropdownB
                title='Board of directors'
                subtitle='Insert Board members'
                doOnClick={() => this.showPopup(0, 'boards')}
                arrowActive={openPopup[0]}/>

              {users.map(u => {
                if (boards.includes(u.id)) {
                  return this.renderUsers(u)
                }
              })}
			  
              {popUp && popUp.status && (popUp.type === 'deputy')
                ? <Popup title={popupProps2.title} subtitle={popupProps2.subtitle} popupStatus={popUp.status}
                         closePopup={() => this.showPopup()}
                         doOnTimes={this.clearField}>
                  <Members
                    {...listProps2}
                    selected={deputy}
                    selectAction={m => this.setState({deputy: m})}
                    users={users}
                    onUpdate={(m) => this.getUserList(m, 'deputy')}
                    refresh={this.refresh}
					btnAddToForm={true}
                    type='deputy'
					typed='boarddeputy' />
                </Popup>
                : null}

              <ButtonDropdownB
                title='Board of Deputy directors'
                subtitle='Insert Deputy Board members'
                doOnClick={() => this.showPopup(2, 'deputy')}
                arrowActive={openPopup[2]}/>

              {users.map(u => {
                if (deputy.includes(u.id)) {
                  return this.renderUsers(u)
                }
              })}

			  <br/>
			<br/>
			<br/>
			<br/>
			<br/>

            </div>
            }
          </div>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
          <div className='text-center'>
            <div className=' btnRegister bottomFixedBtn' onClick={this.save}>
              <i className='far fa-check-circle circle'/>Save company
            </div>
          </div>

        </section>
      </div>
    )
  }

}

export default withRouter(CompanyInfo)
