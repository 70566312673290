import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'

import InputBW from '../../components/InputBW'
import Datepicker from '../../components/Datepicker'
import TextareaBW from '../../components/TextareaBW'

import {get} from '../../action'
import {loadState} from '../../storage'
import Preview from '../Preview'

class NoticeToShareholdersMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      type: 'annual',
      date: new Date(),
      time: new Date(),
      location: '',
      standardAgenda: false,
      issueOfShares: false,
      redemptionOfShares: false,
      changeOfArticle: false,
      electingNewBoardMembers: false,
      shadow: false,
    }
  }

  preview = () => {
    const {company} = loadState();
    get({action: 'preview', model: 'corporate', body: {company_id: company.id, decision: this.state}}).then(res => {
      if (res.success) {
        this.setState({preview: res.html}, () => {
          this.props.history.push('/corporate/1?preview')
        })
      }
    }).catch(err => {
      console.log('err: ', err);
    });
  };

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value}, () => this.validate())
  };

  handleChangeDate = name => date => {
    this.setState({[name]: date});
  };

  componentDidMount() {
    this.validate();
  }

  validate = () => {
    let errors = {};
    let {} = this.state;
    this.setState({errors: errors})
  };

  clearField = name => {
    this.setState({[name]: ''})
  };

  chooseOption = (fieldName, name) => {
    this.setState({[fieldName]: name});
  };

  sendNotice = () => {
    const {history} = this.props;
    const {company} = loadState();
    let {errors} = this.state;
    let countErrs = Object.keys(errors);
    if (countErrs.length === 0) {
      get({model: 'notice', action: 'save', body: {company_id: company.id, company_name: company.name, decision: this.state}}).then(res => {
        // console.log('res: ', res);
        history.goBack();
      }).catch(err => {
        console.log('err: ', err);
      });

    } else {
      this.setState({errSubmit: true})
    }
  };

  render() {
    let {
      openPopup = [],
      errSubmit,
      errors,
      popUp,
      type,
      date,
      time,
      location,
      standardAgenda,
      issueOfShares,
      redemptionOfShares,
      changeOfArticle,
      changesToArticle,
      electingNewBoardMembers,
      shadow,
      preview
    } = this.state;

    const {search} = this.props.location;

    if (preview && search) return <Preview html={preview} close={() => this.setState({preview: ''})}/>;

    return (
      <div>
        <section className='inputPart pbToSeeBottomMenu'>

          <div className='box'>
            <p className='addText'>Select Type of Meeting</p>
            <div className='metingTypeBlock'>
              <div className={`metingType  ${type === 'annual' ? 'metingTypeSelected' : 'pointer'}`}
                   onClick={() => this.setState({type: 'annual'})}>Annual General Meeting
              </div>
              <div className={`metingType  ${type === 'extraordinary' ? 'metingTypeSelected' : 'pointer'}`}
                   onClick={() => this.setState({type: 'extraordinary'})}>Extraordinary Shareholders' Meeting
              </div>
            </div>
          </div>


          <div className='box'>
            <p className='blockTitle addTextMB mt-28'>Date, Time and Venue of Meeting</p>

            <div className='halfInputBW'>
              <Datepicker calendarStyle='calendarStylePurple'
                          label='Date'
                          smallLabel='smallLabelSizeBW'
                          chooseDate={this.handleChangeDate('date')}
                          value={new Date(date)}/>
            </div>

            <div className='halfInputBW2'>
              <Datepicker calendarStyle='calendarStylePurple'
                          timeStyle='timeStylePurple'
                          label='Time'
                          type='time'
                          smallLabel='smallLabelSizeBW'
                          chooseDate={this.handleChangeDate('time')}
                          value={new Date(time)}/>
            </div>

            <InputBW type='text'
                     label='Location'
                     placeholder='Enter Location Here'
                     name='location'
                     value={location}
                     handleChange={this.handleChange}
                     errSubmit={errSubmit}
                     warning={errors.location}
                     doOnTimes={this.clearField}
                     issueStyle='issueStyle'
                     smallLabel='smallLabelSizeBW'
                     smallBtnDelete='deleteONSmallINput'/>
          </div>

          <div className='box'>
            <p className='blockTitle addTextMB additionalTextSmMT'>Annual General Meeting</p>
            <div className='selectedBox'>
              <label className='checkboxContainer mt-6 ml-6'>
                <input type='checkbox' checked={standardAgenda} value={standardAgenda}
                       onChange={() => this.setState({standardAgenda: !standardAgenda})}/>
                <span className='checkmark'/>
              </label>
              <div className='selectedBoxData'>
                <h5>Standard Agenda</h5>
                <p>For Annual General Shareholders' Meeting</p>
              </div>
            </div>
          </div>

          <div className='box'>
            <p className='blockTitle addTextMB mt-28'>Shares</p>
            <div className='selectedBox'>
              <label className='checkboxContainer mt-6 ml-6'>
                <input type='checkbox' checked={issueOfShares} value={issueOfShares}
                       onChange={() => this.setState({issueOfShares: !issueOfShares})}/>
                <span className='checkmark'/>
              </label>
              <div className='selectedBoxData'>
                <h5>Issue of Shares</h5>
                <p>Authorization for Board to Issue Shares</p>
              </div>
            </div>

            {/*<div className='selectedBox'>*/}
            {/*  <label className='checkboxContainer mt-6 ml-6'>*/}
            {/*    <input type='checkbox' checked={redemptionOfShares} value={redemptionOfShares}*/}
            {/*           onChange={() => this.setState({redemptionOfShares: !redemptionOfShares})}/>*/}
            {/*    <span className='checkmark'/>*/}
            {/*  </label>*/}
            {/*  <div className='selectedBoxData'>*/}
            {/*    <h5>Redemption of Shares</h5>*/}
            {/*    <p>Authorization for Board to Redeem Shares</p>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>

          <div className='box'>
            <p className='blockTitle addTextMB mt-28'>Electing new Board Member(s)</p>
            <div className='selectedBox'>
              <label className='checkboxContainer mt-6 ml-6'>
                <input type='checkbox' checked={electingNewBoardMembers} value={electingNewBoardMembers}
                       onChange={() => this.setState({electingNewBoardMembers: !electingNewBoardMembers})}/>
                <span className='checkmark'/>
              </label>
              <div className='selectedBoxData'>
                <h5>Electing new Board Member(s)</h5>
                <p></p>
              </div>
            </div>
          </div>

          <div className='box'>
            <p className='blockTitle addTextMB mt-28'>Change of Articles</p>
            <div className='selectedBox'>
              <label className='checkboxContainer mt-6 ml-6'>
                <input type='checkbox' checked={changeOfArticle} value={changeOfArticle}
                       onChange={() => this.setState({changeOfArticle: !changeOfArticle})}/>
                <span className='checkmark'/>
              </label>
              <div className='selectedBoxData'>
                <h5>Changes of Articles</h5>
                <p>Add changes to Article in next field.</p>
              </div>
            </div>

            <TextareaBW label='Changes to Article'
                        placeholder='Enter changes Here...'
                        name='changesToArticle'
                        value={changesToArticle}
                        handleChange={this.handleChange}
                        errSubmit={errSubmit}
                        warning={errors.changesToArticle}
                        smallLabel='smallLabelSizeBW'/>
          </div>


          <div className='box'>
            <div className='btnAdd' onClick={this.preview}>
              <i className='fas fa-file'/>Preview
            </div>

            <div className='text-center'>
              <div className='btnRegister' onClick={this.sendNotice}>
                <i className='far fa-check-circle circle'/>Send Notice
              </div>
            </div>
          </div>

        </section>
      </div>
    )
  }

}

export default withRouter(NoticeToShareholdersMeeting)
