import React, {Component} from 'react'
import ButtonDropdownW from '../../components/ButtonDropdownW'
import SliderContainer from '../../components/SliderContainer'
import SliderContainerDraft from '../../components/SliderContainerDraft'
import MenuCompanyBottom from '../../components/MenuCompanyBottom'
import {get} from '../../action'
import {loadState} from '../../storage'
import Alert from 'react-s-alert'

export default class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSlider: [],
      data: [],
      data2: []
    };
	
	this.onDelete2 = this.deleteDraft.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // this.getCompanyData();
  }

  componentDidMount() {
    this.getCompanyData();
  }


  getCompanyData = () => {
    const {company} = loadState();
    get({model: 'decision', action: 'list', body: {company_id: company.id}}).then(res => {
      // console.log('res: ', res);
      if(res.success){
        this.setState({data: res.list});
      }
    })
	
	get({model: 'decision', action: 'listdrafts', body: {company_id: company.id}}).then(res => {
      // console.log('res: ', res);
      if(res.success){
        this.setState({data2: res.list});
      }
    })
  };
  
  deleteDraft = (did) => {
	const {company} = loadState();
	const {history} = this.props;
	let {data2}=this.state;
// console.log('did overview:'+did);
	get({action: 'deletedraft', model: 'decision', body: {company_id: company.id, draftid: did } }).then(ress => {
		if (ress.success) {
			Alert.success('Successfully deleted');

			let dat2=data2.filter(x=>x.id !== did);
			
			this.setState({data2: dat2});
			//document.location.reload();
		}
		else {
			Alert.error('Error while deleting');
			return;
		}
	  }).catch(err => {
		Alert.error('Error while deleting 2:'+err);
		return;
	  });	  
  }

  showSlider = i => e => {
    let {openSlider} = this.state;
    openSlider[i] = !openSlider[i];
    this.setState({openSlider: openSlider});
  };

  render() {
    let {openSlider, data, data2} = this.state;
    const {name, history} = loadState();
    const pending = data.filter(x => x.state === 'draft');
    const signed = data.filter(x => x.state === 'signed');
    const drafts = data2;
	
	// console.log(drafts);

    return (
      <div>
        <section className='inputPart pbToSeeBottomMenu'>
          <div className='box'>
            <p className='welcomeText'>Welcome {name} !</p>
            <div>

              <ButtonDropdownW
                title='Draft Documents'
                subtitle='Keskeneräiset dokumentit'
                count={drafts.length <= 9 ? '0' + drafts.length : drafts.length}
                doOnClick={this.showSlider(2)}
                statusActive={openSlider[2]}
              />
              {drafts.length>0?openSlider[2] && <SliderContainerDraft onDelete2={this.onDelete2} sliderCompanyData={drafts} k={2}/>:null}
			  
              <ButtonDropdownW
                title='Pending Signatures'
                subtitle='odottavat allekirjoitukset'
                count={pending.length <= 9 ? '0' + pending.length : pending.length}
                doOnClick={this.showSlider(0)}
                statusActive={openSlider[0]}
              />
              {pending.length>0?openSlider[0] && <SliderContainer sliderCompanyData={pending} k={0}/>:null}

              <ButtonDropdownW
                title='Documents signed'
                subtitle='ASIAKIRJAT ALLEKIRJOITETTU'
                count={signed.length <= 9 ? '0' + signed.length : signed.length}
                doOnClick={this.showSlider(1)}
                statusActive={openSlider[1]}
              />
              {signed.length>0? openSlider[1] && <SliderContainer sliderCompanyData={signed} k={1}/>:null}

            </div>
          </div>
          <MenuCompanyBottom/>
        </section>
      </div>
    )
  }
}
