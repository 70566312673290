import React, {Component} from 'react'
import InputBW from './../components/InputBW'
import SelectedPerson from './../components/SelectedPerson'

export default class PopupAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupStatus: this.props.popupStatus,
    }
  }

  // componentWillReceiveProps(newProps) { // check for the mounted props
  //   if(!newProps.mounted)
  //     return this.unMountStyle() // call outro animation when mounted prop is false
  //   this.setState({ // remount the node when the mounted prop is true
  //     show: true
  //   })
  //   setTimeout(this.mountStyle, 10) // call the into animation
  // }
  // unMountStyle() { // css for unmount animation
  //   this.setState({
  //     style: {
  //       fontSize: 60,
  //       opacity: 0,
  //       transition: 'all 1s ease',
  //     }
  //   })
  // }

  fadeOutDown = () => {
    this.setState({popupStatus: false})
  };

  onAnimationEnd = () => {
    let {popupStatus} = this.state;
    if (!popupStatus) {
      this.props.closePopup()
    }
  };

  render() {
    let {popupStatus} = this.state;
    let {title, subtitle, children, popupSize = ''} = this.props;

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {closePopup: this.props.closePopup})
    );

    return (
      <div className={` box ${popupSize ? popupSize : ''}`}>
        <div className={`popupBlock animated ${popupStatus ? 'fadeInUp' : 'fadeOutDown'}`}
             onAnimationEnd={this.onAnimationEnd}>
          <div className='popTitleBlock'>
            <i className='fas fa-angle-down btnClosePopup' onClick={this.fadeOutDown}/>
            <h1 className='popupTitleMain'>{title}</h1>
            <h3 className='popupSubtitleMain'>{subtitle}</h3>
          </div>
          <div className='mobPadPopup'>
            {childrenWithProps}
          </div>
        </div>
      </div>

    )
  }
}
