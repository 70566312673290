import React, {Component} from 'react'
import PopupAdd from './PopupAdd'

export default class Members extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected,
      popUp: {}
    };
  }

  toggleAll = e => {
    if (e.target.checked) {
      let selected = this.props.users.map(s => parseInt(s.id));
      this.setState({selected});
    } else {
      let selected = [];
      this.setState({selected});
    }
  };

  toggleOne = u => {
    let {selected} = this.state;
    let {id} = u;
    const idx = selected.findIndex(i => i === id);
    if (idx === -1) {
      selected.push(id)
    } else {
      selected = selected.filter(i => i !== id)
    }
    this.setState({selected: selected});
  };

  selectShareholders = () => {
    this.props.selectAction(Array.from(this.state.selected));
    this.props.closePopup();
	// console.log(this.state.selected);
  };

  update = users => {
    const {onUpdate} = this.props;
    if (onUpdate) {
		// console.log(users);
		onUpdate(users);
    }
  };

  showPopup = (type, close = false) => {
    let {popUp = {}} = this.state;
    popUp.status = !popUp.status;
    popUp.type = type;
    this.setState({popUp: popUp});
    if(close){
      if(this.props.refresh){
        this.props.refresh();
      }
      this.props.closePopup();
    }
  };

  render() {
    let {selected, popUp} = this.state;
    let {users = [], type, typed, addBtnText,btnAddToForm} = this.props;
	let type2=type;
	let type3=typed?typed:'?nothere?';
	// console.log(type3);

    return (
      <div>
        {popUp && popUp.status &&
        <PopupAdd customCss={{popupBlock: 't-0'}}
                  type={this.state.popUp.type}
                  popupStatus={popUp.status}
                  onUpdate={this.update}
				  users={users}
				  selected={selected}
                  closePopup={(close) => this.showPopup(type, close)}/>}

        {/*<input className='searchInput' type='text' placeholder={searchPlaceholder}/>*/}

		{btnAddToForm &&
        <div className='btnAdd' onClick={() => this.showPopup(type)}><i className='fas fa-plus'/>{addBtnText}</div>
		}
        <div className='btnAdd text-left' onClick={this.toggleAll}>
          <label className='checkboxContainer'>
            <input type='checkbox'/>
            <span className='checkmark'/>
          </label>
          Select / Deselect all
        </div>

        {users.filter(function (x){return (x.type==type2 || x.type==type3)}).map(u => <div key={u.id} className='selectedBox'>
          <label className='checkboxContainer mt-6 ml-6'>
            <input type='checkbox' checked={selected.includes(u.id)} value={parseInt(u.id)}
                   onChange={() => this.toggleOne(u)}/>
            <span className='checkmark'/>
          </label>
          <div className='selectedBoxData'>
            <h5>{u.name}</h5>
            <p>{u.email}</p>
          </div>
        </div>)
        }

        {/*<p className='endListText'>- End of List -</p>*/}

        <div className='btnSaveSection' onClick={this.selectShareholders}>

          <div className={'checkboxContainer'}>
            <span className='checkCircle'/>
          </div>
          Save Section
        </div>

      </div>)
  }
}
