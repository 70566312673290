import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import logoDark from '../../assets/img/icons/Logo/Logo-onLight.png'
import {auth} from '../action'
import {clearState, loadState} from './../storage'

class SubHeader extends Component {

  logout = () => {
    const state = loadState();
    auth({action: 'logout', body: {id: state.id}}).then(res => {
      if (res.success) {
        clearState();
        this.props.history.push('/');
      }
    })
  };

  render() {
    return (
      <div>
        <section className='inputPart setShadow'>
          <div className='box padB20'>
            <div className='positionMiddle'>
              <img src={logoDark} alt='logo' className='setLogo'/>
              {/*<div className='btnLogout' onClick={this.logout}>Logout</div>*/}
            </div>

            <p className='addText'>Your Company in your Pocket</p>

            {/*<div className='setLineLinks'>*/}
              {/*<a className='setLinks'>About Us</a>*/}
              {/*<a className='setLinks'> Contact Us</a>*/}
              {/*<a className='setLinks'>Visit website</a>*/}
            {/*</div>*/}
          </div>
        </section>
      </div>
    )
  }
}

export default withRouter(SubHeader)
