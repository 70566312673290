import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'

import ButtonDropdownB from '../../components/ButtonDropdownB'
import Popup from '../../components/Popup'
import Members from '../../components/Members'
import DecisionList from '../decision/DecisionList'
import Preview from '../Preview'

import {clearForm, loadState, loadForm, saveForm} from '../../storage'
import {get} from '../../action'

import Alert from 'react-s-alert'
import Datepicker from '../../components/Datepicker'
import InputBW from '../../components/InputBW'

const disabledStyle = {pointerEvents: 'none', opacity: 0.4};

class Board extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPopup: [],
      company_id: loadState().company.id,
      popUp: {},
      type: 'board',
      type2: 'meeting',
      scrutinizer: '',
      chairman: '',
      errors: {},
      users: [],
      members: [],
      _decisions: [],
      loading: false,
      loading2: false,
      preview: '',
      date: new Date(),
      time: new Date(),
      location: ''
    };
  }

  componentWillMount() {
    // this.setState(loadForm())
  }

  componentDidMount() {

// console.log(this.state);

	const {company} = loadState();
	let {did} = this.props.match.params;
	let {draftid} = this.state;

	if (did)
		did = did;
	else
		did = draftid;

// console.log('mountdraft:' + draftid);
// console.log('mountdid:' + did);

    this.setState({draftid: did}, () =>{
		if (did>0)
		{
			get({action: 'loaddraft', model: 'decision', body: {company_id: company.id, draftid: did } }).then(ress => {
				if (ress.success) {
					this.setState(JSON.parse(ress.result.body), () => {
						this.setState({draftid: did}, () => {
							saveForm(this.state);
							
							get({action: 'deletedraft', model: 'decision', body: {company_id: company.id, draftid: did } }).then(ressx => {
								if (ressx.success) {
									Alert.success('Successfully deleted');
								}
								else {
									Alert.error('Error while deleting');
									return;
								}
							  }).catch(err => {
								Alert.error('Error while deleting 2:'+err);
								return;
							  });
							
						})
					});
				}
				else {
					Alert.error('Error while loading');
					return;
				}
				Alert.success('Successfully loaded');
			  }).catch(err => {
				Alert.error('Error while loading 2');
				return;
			  });		
		}
		else
		{
			this.setState(loadForm(), ()=>{
				this.validate();
			});
			this.getUserList();
		}
	});
  }

  getUserList = (m = []) => {
    const {company} = loadState();
    get({model: 'user', action: 'list', body: {company_id: company.id, type: 'boarddeputy', type2: 'board'}}).then(res => {
		
      // this.setState({users: res.list, members: res.list.map(u => u.id)});

	  if (this.state.users.length<1)
		  this.setState({users: res.list});
	  if (this.state.members.length<1)
	      this.setState({members: res.list.map(u => u.id)});
	  if (m.length>0) {
		  this.setState({users: m, members: m.map(u => u.id)});
	  }
    })
  };

  validate = () => {
    let {chairman, scrutinizer, type2, location} = this.state;
    let errors = {};

    if (type2 === 'meeting' && location.length === 0) {
      errors.location = 'Field Location is required!';
    }

    // if (!chairman || chairman.length < 2) {
    //   errors.chairman = 'Field Chairman is required!';
    // }
    // if (!scrutinizer || scrutinizer.length < 2) {
    //   errors.chairman = 'Field Scrutinizer is required!';
    // }

    this.setState({errors: errors})
  };

  handleChangeDate = name => date => {
    this.setState({[name]: date}, () => {
        saveForm(this.state);
        this.validate()
      }
    )
  };

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value}, () => {
        saveForm(this.state);
        this.validate()
      }
    )
  };

  clearField = name => {
    this.setState({[name]: ''}, this.validate)
  };

  submit = () => {
    this.setState({loading: true});
    const {history} = this.props;
    let {errors} = this.state;
    let countErrs = Object.keys(errors);
    if (countErrs.length === 0) {
      get({action: 'save', model: 'decision', body: this.state}).then(res => {
        clearForm();
        history.push('/board');
        Alert.success('Successfully send');
      }).catch(err => {
        Alert.error('Error while saving');
      });
    } else {
      this.setState({errSubmit: true, loading: false})
    }
  };

  savedraft = () => {
    this.setState({loading2: true});

	const {history} = this.props;
	let {errors} = this.state;
	let countErrs = Object.keys(errors);
	if (countErrs.length === 0) {
		
// console.log('savedraft:');		
// console.log(this.state);		
		
	  get({action: 'savedraft', model: 'decision', body: this.state}).then(res => {
		clearForm();
		history.push('/overview');
		Alert.success('Successfully saved');
	  }).catch(err => {
		Alert.error('Error while saving');
	  });
	} else {
	  this.setState({errSubmit: true, loading2: false})
	}
  };


  preview = () => {
	  
	// console.log(this.state);
	  
    get({action: 'preview', model: 'meeting', body: this.state}).then(res => {
      if (res.success) {
        this.setState({preview: res.html}, () => {
          this.props.history.push('/meeting/board?preview')
        })
      }
    }).catch(err => {
      console.log('err: ', err);
    });
  };

  selectBoardMembers = members => {
    this.setState({members: members})
  };

  showPopup = (i, type) => {
    let {openPopup, popUp = {}} = this.state;
    popUp.status = !popUp.status;
    popUp.type = type;
    if (i) {
      openPopup[i] = !openPopup[i];
    } else {
      openPopup = [];
    }
    window.scrollTo(0, 0);
    this.setState({openPopup: openPopup, popUp: popUp});
  };

  select = d => {
    let {_decisions} = this.state;
    let {history} = this.props;
    let idx = _decisions.push(d);
    this.setState({_decisions: _decisions}, () => {
      this.showPopup();
      saveForm(this.state);
      history.push(`/decision/issue/${idx - 1}/${d.id}/board`)
    })
  };

  removeDecision = (idx, item) => {
    let {decisions, _decisions} = this.state;
    // console.log('_decisions', _decisions);
	if (decisions.length>0)
		decisions = decisions.filter(d => d.id != item.id);
    _decisions = _decisions.filter(d => d.id !== item.id);
    this.setState({_decisions: _decisions, decisions: decisions}, () => {
      saveForm(this.state)
    });
  };

  renderDecisions = (item, idx) => {
    const {id, icon, name, name_fn} = item;
    return (
      <div key={id}>
        <i className='fas fa-times-circle pull-right deleteSelectedBoardMeetin'
           onClick={() => this.removeDecision(idx, item)}/>
        <div className='selectedBox selectedBoardMeeting' key={`id-d-${idx}`}>
          <Link to={`/decision/issue/${idx}/${id}/board`}>
            {/*<div className='decisionIconBox'>*/}
            {/*<img className='decisionIcon' src={`/front/assets/img/icons/Blue/${icon}`} alt=''/>*/}
            {/*</div>*/}
            <div className='selectedBoxData'>
              <h5>{name}</h5>
              <p>{name_fn}</p>
            </div>
          </Link>
        </div>
      </div>
    )
  };

  renderUsers = u => {
    return (
      <div key={u.id} className='selectedBox'>
        <div className='selectedBoxData'>
          <h5>{u.name}</h5>
          <p>{u.email}</p>
        </div>
      </div>
    )
  };

  render() {
    let {users, members, _decisions, errSubmit, errors, type2, chairman = '', scrutinizer = '', popUp = {}, openPopup, preview, loading,loading2, date, time, location} = this.state;
    const {search} = this.props.location;

    let {pageType} = this.props.match.params;
    let popupProps = {},
      listProps = {},
      BtnDropdownB;

    popupProps = {
      title: 'Board Members',
      subtitle: 'Hallituksen jäsenet',
    };

    listProps = {
      searchPlaceholder: 'Search Members (Total 10)',
      addBtnText: 'Add new Board member'

    };
    BtnDropdownB = {
      title: 'members present',
      subtitle: 'All members selected (Default)'
    };

    let btn = _decisions.length > 0;

    if (preview && search) return <Preview html={preview} close={() => this.setState({preview: ''})}/>;

    return (
      <div>
        <div className={`shade ${popUp && popUp.status ? 'shadeActive' : ' '}`}/>
        <section className='inputPart'>

          {popUp && popUp.status && ( popUp.type === 'board' )
            ? <Popup title={popupProps.title} subtitle={popupProps.subtitle} popupStatus={popUp.status}
                     closePopup={() => this.showPopup()}
                     doOnTimes={this.clearField}>
              <Members
                {...listProps}
                type='board'
				typed='boarddeputy'
                selected={members}
                selectAction={this.selectBoardMembers}
                onUpdate={(m) => this.getUserList(m)}
                users={users}
				btnAddToForm={false}
              />
            </Popup>
            : null}

          {popUp && popUp.status && popUp.type === 'unanimous'
            ? <Popup title='Board decision' 
					 subtitle='Hallituksen päätös'
					 popupStatus={popUp.status}
                     closePopup={() => this.showPopup()}
                     doOnTimes={this.clearField}>
              <DecisionList onSelect={this.select} selected={_decisions} type='board'/>
            </Popup>
            : null}


          <div className='box' style={{marginBottom: '20%'}}>
            <p className='addText'>Select Type of Meeting</p>
            <div className='metingTypeBlock'>
              <div className={`metingType  ${type2 === 'meeting' ? 'metingTypeSelected' : 'pointer'}`}
                   onClick={() => this.setState({type2: 'meeting'}, this.validate)}>Meeting
              </div>
              <div className={`metingType  ${type2 === 'unanimous' ? 'metingTypeSelected' : 'pointer'}`}
                   onClick={() => this.setState({type2: 'unanimous'}, this.validate)}>Unanimous written decision
              </div>
            </div>

            <p className='addText pt-20'>Information about Meeting</p>

            <div className='box'>
              <p className='blockTitle addTextMB mt-28'>Date, Time and Venue of Meeting</p>

              <div className='halfInputBW'>
                <Datepicker calendarStyle='calendarStylePurple'
                            label='Date'
                            smallLabel='smallLabelSizeBW'
                            chooseDate={this.handleChangeDate('date')}
                            value={new Date(date)}/>
              </div>

              <div className='halfInputBW2'>
                <Datepicker calendarStyle='calendarStylePurple'
                            ref={el => this.picker = el}
                            timeStyle='timeStylePurple'
                            label='Time'
                            type='time'
                            smallLabel='smallLabelSizeBW'
                            chooseDate={this.handleChangeDate('time')}
                            value={new Date(time)}/>
              </div>

              <InputBW type='text'
                       label='Location'
                       placeholder='Enter Location Here'
                       name='location'
                       value={location}
                       handleChange={this.handleChange}
                       errSubmit={errSubmit}
                       warning={errors.location}
                       doOnTimes={this.clearField}
                       issueStyle='issueStyle'
                       smallLabel='smallLabelSizeBW'
                       smallBtnDelete='deleteONSmallINput'/>
            </div>

            <ButtonDropdownB
              title={BtnDropdownB.title}
              subtitle={BtnDropdownB.subtitle}
              doOnClick={() => this.showPopup(0, 'board')}
              arrowActive={openPopup[0]}
            />

            {users.map(u => {
              if (members.includes(u.id)&& ( (u.type=='board') || (u.type=='boarddeputy') ) ) {
                return this.renderUsers(u)
              }
            })}

            <p className='addText'>Decisions Made</p>

            <div className='btnAdd' onClick={() => this.showPopup(null, 'unanimous')}>
              <i className='fas fa-plus'/>Add Decision to Meeting
            </div>

            {_decisions.map(this.renderDecisions)}

            <div className='btnAdd' onClick={this.preview} style={!btn ? disabledStyle : {}}>
              <i className='fas fa-file'/>Preview
            </div>

            <div className='btnAdd' onClick={this.savedraft} style={!btn ? disabledStyle : {}}>
              <i className='fas fa-file'/>Save draft {loading2 &&
            <i className='fa fa-spinner fa-pulse fa-fw'/>}
            </div>

            <div className='btnRegister' onClick={this.submit} style={!btn ? disabledStyle : {}}>
              <i className='fab fa-telegram-plane planeIcon'/>Send for Signatures {loading &&
            <i className='fa fa-spinner fa-pulse fa-fw'/>}
            </div>

          </div>

        </section>
      </div>
    )
  }

}

export default withRouter(Board)
