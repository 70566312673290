import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import InputBW from './InputBW'
import SelectedPerson from './SelectedPerson'
import {loadState} from '../storage'
import {get} from '../action'

class PopupAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      popupStatus: this.props.popupStatus,
      users: this.props.users,
      name: '',
      email: ''
    }
  }

  clearField = name => {
    this.setState({[name]: ''}, () => {
      this.validate()
    })
  };

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value}, () => this.validate())
  };

  componentDidMount() {
    this.validate();
    this.getUserList();
  }

  getUserList = () => {
    // const {company} = loadState();
    // get({model: 'user', action: 'list', body: {company_id: company.id, type: this.props.type}}).then(res => {
      // this.setState({users: res.list});
    // })
  };

  validate = () => {
    this.setState({errors: {}});
    let {name, email} = this.state;
    let errors = {};
    let cName = name;
    let cEmail = email;

    if (!cName || cName.length < 2) {
      errors.cName = 'Field Name is required!';
    }
    if (!cEmail || cEmail.length < 3) {
      errors.cEmail = 'Field Email is required!';
    }

    this.setState({errors: errors})
  };

  addPosition = () => {
    const {type, onUpdate, closePopup, users, selected} = this.props;
    let {errors, name, email} = this.state;
    let countErrs = Object.keys(errors);
	
	// console.log('cerres:'+countErrs);
	
    if (countErrs.length == 0) {
      const {company} = loadState();
      // get({model: 'user', action: 'list', body: {company_id: company.id, name: name, email: email, type: type}})
        // .then(res => {
          // if (res.success) {
			let iarr=users.filter(el=>el.id<0);
			let i=0;
			// console.log(iarr);
			if (iarr.length>0)
				i=-1*iarr.length;
			let users2=users.slice();

			// console.log(selected);
			// console.log(users2);

			users2=users2.filter((el)=>selected.includes(el.id));
			if ((users2.filter((el)=>el.id==(i-1))).length<1)
				users2.push({id:i-1, name: name, email: email, type: type});
			if ((selected.filter((el)=>el==(i-1))).length<1)
				selected.push(i-1);
			
			console.log(users2);
			
            this.setState({selected: selected, users: users2, name: '', email: ''}, () => {
              onUpdate(users2);
              closePopup(true);
            });
          // }
        // });
    } else {
      this.setState({errSubmit: true})
    }
  };

  fadeOutDown = () => {
    this.setState({popupStatus: false})
  };

  onAnimationEnd = () => {
    let {popupStatus} = this.state;
    if (!popupStatus) {
      this.props.closePopup()
    }
  };

  renderUsers = (item, i) => {
    return <SelectedPerson user={item} key={`u-i-${i}`}/>
  };

  render() {
    let {users, errors, errSubmit, popupStatus, name, email} = this.state;
    let {type, customCss} = this.props;
    const position = (type === 'shareholder') ? 'Shareholder (and its representative, if a company)' : 'Board member';
    const position2 = (type === 'shareholder') ? 'Shareholder' : 'Board member';
    const positiontext = (type === 'shareholder') ? 'Enter Name Here...(e.g. Company A, represented by B)' : 'Enter name Here...';
    const title = (type === 'shareholder') ? 'Osakkeenomistajat' : 'Lisää uusi hallituksen jäsen';

    return (
      <div className='box popUpBoxWidth'>
        <div
          className={`popupBlock animated ${popupStatus ? 'fadeInUp' : 'fadeOutDown'} ${customCss && customCss.popupBlock ? customCss.popupBlock : ''}`}
          onAnimationEnd={this.onAnimationEnd}>
          <div className='popTitleBlock'>
            <i className='fas fa-angle-down btnClosePopup' onClick={this.fadeOutDown}/>
            <h1 className='popupTitleMain'>{position}</h1>
            <h3 className='popupSubtitleMain'>{title}</h3>
          </div>
          <div className='mobPadPopup'>
            <p className='addText positionAddText'>Add New {position}</p>
            <InputBW label={`Name of new ${position}`}
                     doOnTimes={this.clearField}
                     placeholder={positiontext}
                     name='name'
                     value={name}
                     handleChange={this.handleChange}
                     errSubmit={errSubmit}
                     warning={errors.cName}
                     mb='popupMB'
                     mt='warningTopM0'/>

            <InputBW label={`Email of new ${position2}`}
                     doOnTimes={this.clearField}
                     placeholder='Enter Email Here...'
                     name='email'
                     value={email}
                     handleChange={this.handleChange}
                     errSubmit={errSubmit}
                     warning={errors.cEmail}
                     mb='popupMB'
                     mt='warningTopM0'/>

            <div className='btnAdd' onClick={this.addPosition}>
              <i className='fas fa-plus'/>Add new {position2}
            </div>

            {/*<input className='searchInput' type='text' placeholder={`Search ${position}`}/>*/}

				{/*<div className='scrollBoxSelected'>*/}

              {/* {users.map(this.renderUsers)}*/}
				
              {/*<p className='endListText'>- End of List -</p>*/}
				  {/*
            </div>
			*/}
          </div>
        </div>
      </div>

    )
  }
}

export default withRouter(PopupAdd)
