import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'

export default class MenuCompanyBottom extends Component {
  render() {
    return (
      <div>
        <div className='boxS'>
          <div className='menuBottom'>

            <NavLink to={'/board'} className='menuBItem' activeClassName='activeMenuBItem'>
              <i className='fas fa-user-circle'/><br/>
              <span>Board</span>
            </NavLink>

            <NavLink to={'/shareholders'} className='menuBItem' activeClassName='activeMenuBItem'>
              <i className='fas fa-money-check-alt'/><br/>
              <span>Shareholders</span>
            </NavLink>

            <NavLink to={'/overview'} className='menuBItem' activeClassName='activeMenuBItem'>
              <i className='fas fa-chart-bar'/><br/>
              <span>Overview</span>
            </NavLink>

            <NavLink to={'/corporate'} className='menuBItem' activeClassName='activeMenuBItem'>
              <i className='fas fa-city'/><br/>
              <span>Corporate</span>
            </NavLink>

            {/*<a href='#' className='menuBItem'>*/}
            {/*<i className='fas fa-chart-pie'/><br/>*/}
            {/*<span>Cap Table</span>*/}
            {/*</a>*/}

          </div>
        </div>
      </div>
    )
  }
}
