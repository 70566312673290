import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'
import ButtonDropdownW from '../components/ButtonDropdownW'
import {get} from '../action'
import {loadState, saveState} from '../storage'

class ManageUserRights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      boardemails: []
    }
  }

  componentWillMount() {
    this.getUsers();
  }

  getUsers = () => {
	const {company} = loadState();
    get({model: 'user', action: 'list', body: {company_id: company.id, type:'serviceuser'}}).then(res => {
		// console.log('res: ', res);
		this.setState({users: res.list}, ()=>{
				get({model: 'user', action: 'listemails', body: {company_id: company.id, type:'board'}}).then(res => {
					this.setState({boardemails: res.list});
				})
		});
    })

  };

  close = () => {
	const {history} = this.props;
	history.replace('/settings')
  };
  selectUser = company => {
  };

  // renderUsers = (item, i) => {
    // return (
      // <ButtonDropdownW title={item.email}
                       // key={`comp-${i}`}
                       // subtitle={null}
                       // doOnClick={() => this.selectUser(item)}
                       // statusActive={null}/>
    // )
  // };
  removeUser = (item) => {
 	const {history} = this.props;
	const {company, id} = loadState();
	
	if ((id!=item.id)||(id==item.id)&&(confirm('Do you really want to remove yourself from serviceusers?')))
		get({model: 'user', action: 'deletecompanyuser', body: {company_id: company.id, id:item.id}}).then(res => {
					if (id==item.id)
						history.replace('/select/company');
					else
						this.getUsers();
					
				});
  };

  renderUsers = (item, i) => {
	const {boardemails}=this.state;
  
    return (<div key={`d-${i}`} className='selectedBox'>
	  {
		  (boardemails.filter(e=>{return(e.email == item.email)}).length<1)
	  ? <div className='fas fa-times-circle pull-left deleteSelectedBoardMeetin' onClick={() => {this.removeUser(item)} } />
	  : null}

	  {item.email}
	  </div>
    )
  };

  render() {
    const {users} = this.state;
    return (
      <div>
        <section className='inputPart pbToSeeBottomMenu'>
          <div className='box'>
            {users.map(this.renderUsers)}
          </div>
		  <br/>
		  <br/>
		  <br/>
		  <div className='box'>
            <Link to='/settings/addserviceuser'>
              <div className='btnAdd '><i className='fas fa-plus'/>Add Service User</div>
            </Link>
          </div>
		  <div className='text-center'>
            <div className=' btnRegister bottomFixedBtn' onClick={this.close}>
              <i className='far fa-check-circle circle'/>Close
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withRouter(ManageUserRights)
