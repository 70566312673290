import React, {Component} from 'react'
import ButtonDropdownW from './../components/ButtonDropdownW'
import SubHeader from './../components/SubHeader'
import {withRouter} from 'react-router-dom'

const btnSettings = [
  {
    title: 'Company information',
    subtitle: 'Yrityksen tiedot',
    redirectTo: '/company/info',
    disabled: false
  },
  {
    title: 'Set user password',
    subtitle: 'Vaihda salasana',
    redirectTo: '/settings/password',
    disabled: false
  },
  {
    title: 'Manage user rights',
    subtitle: 'Hallinnoi käyttöoikeuksia',
    redirectTo: '/settings/manageuserrights',
    disabled: false
  },  
  {
    title: 'manage subscription',
    subtitle: 'Muuta tilaustyyppiä',
    redirectTo: '',
    disabled: true
  },
  {
    title: 'language preferences',
    subtitle: 'Kieliasetukset',
    redirectTo: '',
    disabled: true
  },
  {
    title: 'Help',
    subtitle: 'Apua',
    redirectTo: '/settings/help',
    disabled: false
  },
  {
    title: 'terminate subscription',
    subtitle: 'lopeta tilaus',
    redirectTo: '',
    disabled: true
  }
];

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openButton: []
    };
  }

  openPage = (i, goTo) => {
    let {openButton} = this.state;
    openButton[i] = !openButton[i];
    this.setState({openButton: openButton});
    this.props.history.push(goTo);
  };

  renderMenu = (item, i) => {
    const {openButton} = this.state;
    const disabled = {pointerEvents: 'none', opacity: 0.4};
    return (
      <ButtonDropdownW title={item.title}
                       key={`m-b-${i}`}
                       style={item.disabled ? disabled : {}}
                       subtitle={item.subtitle}
                       doOnClick={() => this.openPage(i, (item.redirectTo))}
                       statusActive={openButton[i]}/>
    )
  };

  render() {
    return (
      <div>
        <SubHeader/>
		
        <section className='text-center'>
          <div className='box'>
            {btnSettings.map(this.renderMenu)}
            {/*<p className='endListText'>- End of List -</p>*/}
          </div>
        </section>
      </div>
    )
  }
}

export default withRouter(Settings)
