import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'

import InputBW from '../../components/InputBW'

import Alert from 'react-s-alert'

import {get} from '../../action'
import {loadState, loadForm, clearForm, saveForm} from '../../storage'
import Preview from '../Preview'

class ConfirmationForShareIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
	  company_id: loadState().company.id,
      errors: {},
      type: 'confirmation',
      type2: 'confirmation',
	  date:'',
	  namemd:'',
	  emailmd:'',
	  nameau:'',
	  emailau:'',
	  preview:'',
	  loading2:false
    }
  }
	
  componentDidMount() {
	const {company} = loadState();

	let {did} = this.props.match.params;
	let {draftid} = this.state;

	if (did)
		did = did;
	else
		did = draftid;

// console.log('mountdraft:' + draftid);
// console.log('mountdid:' + did);

    this.setState({draftid: did}, () =>{
		if (did>0)
		{
			get({action: 'loaddraft', model: 'decision', body: {company_id: company.id, draftid: did } }).then(ress => {
				if (ress.success) {
					this.setState(JSON.parse(ress.result.body), () => {
						this.setState({draftid: did}, () => {
							saveForm(this.state);
							
							get({action: 'deletedraft', model: 'decision', body: {company_id: company.id, draftid: did } }).then(ressx => {
								if (ressx.success) {
									Alert.success('Successfully deleted');
								}
								else {
									Alert.error('Error while deleting');
									return;
								}
							  }).catch(err => {
								Alert.error('Error while deleting 2:'+err);
								return;
							  });
						})
					});
				}
				else {
					Alert.error('Error while loading');
					return;
				}
				Alert.success('Successfully loaded');
			  }).catch(err => {
				Alert.error('Error while loading 2 -draft deleted');
				return;
			  });		
		}
		else
		{
			this.setState(loadForm(), ()=>{
				this.validate();
			});
		}
	});
  }
  
  preview = () => {
    const {company} = loadState();
    get({action: 'preview', model: 'confirmation', body: {company_id: company.id, decision: this.state}}).then(res => {
      if (res.success) {
        this.setState({preview: res.html}, () => {
          this.props.history.push('/confirmation?preview')
        })
      }
    }).catch(err => {
      console.log('err: ', err);
    });
  };

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value}, () => this.validate())
  };

  validate = () => {
    let errors = {};
    let {} = this.state;
    this.setState({errors: errors})
  };

  clearField = name => {
    this.setState({[name]: ''})
  };

  chooseOption = (fieldName, name) => {
    this.setState({[fieldName]: name});
  };

  sendConfirmation = () => {
    const {history} = this.props;
    const {company} = loadState();
    let {errors} = this.state;
    let countErrs = Object.keys(errors);
    if (countErrs.length === 0) {
      get({model: 'confirmation', action: 'save', body: {company_id: company.id, company_name: company.name, decision: this.state}}).then(res => {
        // console.log('res: ', res);
        history.goBack();
      }).catch(err => {
        console.log('err: ', err);
      });

    } else {
      this.setState({errSubmit: true})
    }
  };
  
  savedraft = () => {
    this.setState({loading2: true});
	const {history} = this.props;
	let {errors} = this.state;
	let countErrs = Object.keys(errors);
	if (countErrs.length === 0) {
	  get({action: 'savedraft', model: 'decision', body: this.state}).then(res => {
		clearForm();
		history.push('/corporate');
		Alert.success('Successfully saved');
	  }).catch(err => {
		Alert.error('Error while saving');
	  });
	} else {
	  this.setState({errSubmit: true, loading2: false})
	}
  };
  
  render() {
    let {
      openPopup = [],
      errSubmit,
      errors,
      popUp,
      type,
	  date,
	  namemd,
	  emailmd,
	  nameau,
	  emailau,
      preview,
	  loading2
    } = this.state;

	let btn = true;
	
    const {search} = this.props.location;

    if (preview && search) return <Preview html={preview} close={() => this.setState({preview: ''})}/>;

    return (
      <div>
        <section className='inputPart pbToSeeBottomMenu'>

          <div className='box'>

            <InputBW type='text'
                     label='Date of the share issue decision'
                     placeholder='Enter Date Here (2021-09-01)'
                     name='date'
                     value={date}
                     handleChange={this.handleChange}
                     errSubmit={errSubmit}
                     warning={errors.date}
                     doOnTimes={this.clearField}
                     issueStyle='issueStyle'
                     smallLabel='smallLabelSizeBW'
                     smallBtnDelete='deleteONSmallINput'/>
          </div>

          <div className='box'>

            <InputBW type='text'
                     label='Name of Managing Director'
                     placeholder='Enter name of Managing Director'
                     name='namemd'
                     value={namemd}
                     handleChange={this.handleChange}
                     errSubmit={errSubmit}
                     warning={errors.namemd}
                     doOnTimes={this.clearField}
                     issueStyle='issueStyle'
                     smallLabel='smallLabelSizeBW'
                     smallBtnDelete='deleteONSmallINput'/>
          </div>

          <div className='box'>

            <InputBW type='text'
                     label='Managing Director’s email'
                     placeholder='Enter Managing Director’s email address'
                     name='emailmd'
                     value={emailmd}
                     handleChange={this.handleChange}
                     errSubmit={errSubmit}
                     warning={errors.emailmd}
                     doOnTimes={this.clearField}
                     issueStyle='issueStyle'
                     smallLabel='smallLabelSizeBW'
                     smallBtnDelete='deleteONSmallINput'/>
          </div>
          <div className='box'>

            <InputBW type='text'
                     label='Name of Auditor'
                     placeholder='Enter name of Auditor'
                     name='nameau'
                     value={nameau}
                     handleChange={this.handleChange}
                     errSubmit={errSubmit}
                     warning={errors.nameau}
                     doOnTimes={this.clearField}
                     issueStyle='issueStyle'
                     smallLabel='smallLabelSizeBW'
                     smallBtnDelete='deleteONSmallINput'/>
          </div>
          <div className='box'>

            <InputBW type='text'
                     label='Auditor’s email'
                     placeholder='Enter Auditor’s email address'
                     name='emailau'
                     value={emailau}
                     handleChange={this.handleChange}
                     errSubmit={errSubmit}
                     warning={errors.emailau}
                     doOnTimes={this.clearField}
                     issueStyle='issueStyle'
                     smallLabel='smallLabelSizeBW'
                     smallBtnDelete='deleteONSmallINput'/>
          </div>

          <div className='box'>
            <div className='btnAdd' onClick={this.preview}>
              <i className='fas fa-file'/>Preview
            </div>

            <div className='btnAdd' onClick={this.savedraft} style={!btn ? disabledStyle : {}}>
              <i className='fas fa-file'/>Save draft {loading2 &&
			  <i className='fa fa-spinner fa-pulse fa-fw'/>}
            </div>

            <div className='text-center'>
              <div className='btnRegister' onClick={this.sendConfirmation}>
                <i className='far fa-check-circle circle'/>Send Confirmation
              </div>
            </div>
          </div>

        </section>
      </div>
    )
  }

}

export default withRouter(ConfirmationForShareIssue)
