import {loadState} from './storage'

// const URL = 'http://pi';
const URL = 'https://pocketinc.fi';

const PUBLIC_URL = `${URL}/public`;
const API_URL = `${URL}/api`;
const AUTH_URL = `${URL}/auth`;

export function get(body) {
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  let params = {method: 'POST', headers: headers, mode: 'cors', cache: 'default'};
  const state = loadState();
  const token = state && state.auth_token ? state.auth_token : '';
  headers.append('Authorization', `Bearer ${token}`);
  params.body = JSON.stringify(body);
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}`, params)
      .then(function (response) {
        return response.json();
      })
      .then(resolve)
      .catch(reject);
  })
}

export function auth(body = {}) {
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  let params = {method: 'POST', headers: headers, mode: 'cors', cache: 'default'};
  params.body = JSON.stringify(body);
  return new Promise((resolve, reject) => {
    fetch(`${AUTH_URL}`, params)
      .then(function (response) {
        return response.json();
      })
      .then(resolve)
      .catch(reject);
  })
}

export function getDocument(id) {
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  let params = {method: 'POST', headers: headers, mode: 'cors', cache: 'default'};
  const state = loadState();
  const token = state && state.auth_token ? state.auth_token : '';
  headers.append('Authorization', `Bearer ${token}`);
  params.body = JSON.stringify({model: 'meeting', action: 'pdf', body: {id: id}});
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}`, params)
      .then(function (response) {
        return response.json();
      })
      .then(resolve)
      .catch(reject);
  })
}
export function getDraftDocument(company_id, id) {
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  let params = {method: 'POST', headers: headers, mode: 'cors', cache: 'default'};
  const state = loadState();
  const token = state && state.auth_token ? state.auth_token : '';
  headers.append('Authorization', `Bearer ${token}`);
  params.body = JSON.stringify({model: 'meeting', action: 'draftpdf', body: {company_id:company_id, id: id}});
  return new Promise((resolve, reject) => {
    fetch(`${API_URL}`, params)
      .then(function (response) {
        return response.json();
      })
      .then(resolve)
      .catch(reject);
  })
}

export function openDocument(action, hash) {
  let headers = new Headers({'Accept': 'application/json', 'Content-Type': 'application/json'});
  let params = {method: 'POST', headers: headers, mode: 'cors', cache: 'default'};
  params.body = JSON.stringify({model: 'meeting', action: action, body: {hash: hash}});
  return new Promise((resolve, reject) => {
    fetch(`${PUBLIC_URL}`, params)
      .then(function (response) {
        return response.json();
      })
      .then(resolve)
      .catch(reject);
  })
}

export function forgotPassword(email) {
  let headers = new Headers({'Accept': 'application/json', 'Content-Type': 'application/json'});
  let params = {method: 'POST', headers: headers, mode: 'cors', cache: 'default'};
  params.body = JSON.stringify({model: 'user', action: 'forgotpassword', email: email});
  return new Promise((resolve, reject) => {
    fetch(`${PUBLIC_URL}`, params)
      .then(function (response) {
        return response.json();
      })
      .then(resolve)
      .catch(reject);
  })
}

export function resetPassword(hash, newpassword) {
  let headers = new Headers({'Accept': 'application/json', 'Content-Type': 'application/json'});
  let params = {method: 'POST', headers: headers, mode: 'cors', cache: 'default'};
  params.body = JSON.stringify({model: 'user', action: 'resetpassword', body: {hash: hash, password: newpassword}});
  return new Promise((resolve, reject) => {
    fetch(`${PUBLIC_URL}`, params)
      .then(function (response) {
        return response.json();
      })
      .then(resolve)
      .catch(reject);
  })
}

export function download(action, id) {
  let headers = new Headers({'Accept': 'application/json', 'Content-Type': 'application/json'});
  let params = {method: 'POST', headers: headers, mode: 'cors', cache: 'no-cache'};
  params.body = JSON.stringify({model: 'meeting', action: action, body: {id: id}});
  return new Promise((resolve, reject) => {
    fetch(`${PUBLIC_URL}`, params)
      .then(response => response.blob())
      .then(resolve)
      .catch(reject);
  })
}
