import React, {Component} from 'react'
import {get} from '../../action'

export default class DecisionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
  }

  getDecisionTypes = () => {
    const {type} = this.props;
    get({model: 'decisiontype', action: 'list', body: {type: type}}).then(res => {
      this.setState({list: res.list});
    })
  };

  componentWillMount() {
    this.getDecisionTypes();
  }

  renderTypes = item => {
    const selected = this.props.selected.map(i => parseInt(i.id));
    const disabled = {pointerEvents: 'none', opacity: 0.4};
    const {id, icon, name, name_fn, state} = item;
    const dis = state === 0;
    const sel = selected.includes(item.id);

    return <div className='selectedBox' key={`id-d-${id}`} style={dis || sel ? disabled : null}
                onClick={dis || sel ? null : () => this.props.onSelect(item)}>
      <div className='decisionIconBox'>
        <img className='decisionIcon' src={`/assets/img/icons/Blue/${icon}`} alt=''/>
      </div>
      <div className='selectedBoxData'>
        <h5>{name}</h5>
        <p>{name_fn}</p>
      </div>
      {sel && <i className='fas fa-check-circle pull-right'/>}
    </div>
  };

  render() {
    const {list} = this.state;
    return (
      <div className={'mt-28'}>
        {list.map(this.renderTypes)}
        {/*<Link to='#' className='learnMoreLink'><i className='far fa-question-circle'/>*/}
        {/*Learn more about Decision Types / Lisätietoja päätöksentyypeistä*/}
        {/*</Link>*/}
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>

      </div>
    )
  }
}
