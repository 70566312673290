import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import InputBW from '../../components/InputBW'
import TextareaBW from '../../components/TextareaBW'
import {loadForm, saveForm} from '../../storage'

class AmendingArticles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    }
  }

  componentWillMount() {
    const {idx, id} = this.props.match.params;
    const form = loadForm();
    if (form.decisions && form.decisions[idx]) {
      this.setState(form.decisions[idx].content)
    }
  }

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value}, () => this.validate())
  };

  handleChangeDate = name => date => {
    this.setState({[name]: date});
  };

  componentDidMount() {
    this.validate();
  }

  validate = () => {
    let errors = {};
    let {numberOfMembers_1,numberOfMembers_2,numberOfDeputies_1,numberOfDeputies_2, amendingArticlesTailored} = this.state;

    if (amendingArticlesTailored && amendingArticlesTailored.length>0) {
      this.setState({errors: errors});
      return;
    }

    if (!numberOfMembers_1 || numberOfMembers_1.length < 1) {
      errors.numberOfMembers_1 = 'Required!';
    }
    if (!numberOfMembers_2 || numberOfMembers_2.length < 1) {
      errors.numberOfMembers_2 = 'Required!';
    }
    if (!numberOfDeputies_1 || numberOfDeputies_1.length < 1) {
      errors.numberOfDeputies_1 = 'Required!';
    }
    if (!numberOfDeputies_2 || numberOfDeputies_2.length < 1) {
      errors.numberOfDeputies_2 = 'Required!';
    }
	
    this.setState({errors: errors});
	
  };

  clearField = name => {
    this.setState({[name]: ''})
  };

  showPopup = (i, type) => {
    let {openPopup = [], popUp = {}} = this.state;
    popUp.status = !popUp.status;
    popUp.type = type;
    if (i) {
      openPopup[i] = !openPopup[i];
    } else {
      openPopup = [];
    }
    window.scrollTo(0, 0);
    this.setState({openPopup: openPopup, popUp: popUp});
  };


  chooseOption = (fieldName, name) => {
    this.setState({[fieldName]: name}, () => this.validate());
  };

  saveDecision = () => {
    const {history, match} = this.props;
    const {idx, id, ret} = match.params;
    let {errors} = this.state;

    let countErrs = Object.keys(errors);
    if (countErrs.length === 0) {
      let form = loadForm();
      if (!form.decisions) {
        form.decisions = [{id: id, content: this.state}];
      } else {
        form.decisions[idx] = {id: id, content: this.state};
      }
      saveForm(form);
      // history.goBack();
	  if (ret=='board')
		this.props.history.replace('/meeting/board');
	  else
		this.props.history.replace('/meeting/shareholders');
    } else {
      this.setState({errSubmit: true})
    }
  };

  render() {
    let {
      openPopup = [],
      errSubmit,
      errors,
      popUp,

      numberOfMembers_1=0,
      numberOfMembers_2=0,
      numberOfDeputies_1=0,
      numberOfDeputies_2=0,
	  addRedemptionClause=false,
	  addConsentClause=false,
      amendingArticlesTailored=""

    } = this.state;
    return (
      <div>
		<p className='addText'>Select Type of Authorization</p>
        <div className={`shade ${popUp && popUp.status ? 'shadeActive' : ' '}`}/>
        <section className='inputPart pbToSeeBottomMenu'>
          <div className='box'>
            <p className='blockTitle addTextMB '>Number of Ordinary Members</p>
            <div className='smallInputBW'>
              <InputBW type='number'
                       label='Minimum'
                       name='numberOfMembers_1'
                       placeholder='0'
                       value={numberOfMembers_1}
                       handleChange={this.handleChange}
                       errSubmit={errSubmit}
                       warning={errors.numberOfMembers_1}
                       doOnTimes={this.clearField}
                       issueStyle='issueStyle'
                       numberStyle='numberField'
                       smallLabel='smallLabelSizeBW'
                       smallBtnDelete='deleteONSmallINput'/>
              <InputBW type='number'
                       label='Maximum'
                       name='numberOfMembers_2'
                       placeholder='0'
                       value={numberOfMembers_2}
                       handleChange={this.handleChange}
                       errSubmit={errSubmit}
                       warning={errors.numberOfMembers_2}
                       doOnTimes={this.clearField}
                       issueStyle='issueStyle'
                       numberStyle='numberField'
                       smallLabel='smallLabelSizeBW'
                       smallBtnDelete='deleteONSmallINput'/>
            </div>

          </div>

          <div className='box'>
            <p className='blockTitle addTextMB '>Number of Deputy Members</p>
            <div className='smallInputBW'>
              <InputBW type='number'
                       label='Minimum'
                       name='numberOfDeputies_1'
                       placeholder='0'
                       value={numberOfDeputies_1}
                       handleChange={this.handleChange}
                       errSubmit={errSubmit}
                       warning={errors.numberOfDeputies_1}
                       doOnTimes={this.clearField}
                       issueStyle='issueStyle'
                       numberStyle='numberField'
                       smallLabel='smallLabelSizeBW'
                       smallBtnDelete='deleteONSmallINput'/>
              <InputBW type='number'
                       label='Maximum'
                       name='numberOfDeputies_2'
                       placeholder='0'
                       value={numberOfDeputies_2}
                       handleChange={this.handleChange}
                       errSubmit={errSubmit}
                       warning={errors.numberOfDeputies_2}
                       doOnTimes={this.clearField}
                       issueStyle='issueStyle'
                       numberStyle='numberField'
                       smallLabel='smallLabelSizeBW'
                       smallBtnDelete='deleteONSmallINput'/>
            </div>

          </div>
		  
          <div className='box'>
            <p className='blockTitle addTextMB additionalTextSmMT'>Clauses</p>
            <div className='selectedBox'>
              <label className='checkboxContainer mt-6 ml-6'>
                <input type='checkbox' checked={addRedemptionClause} value={addRedemptionClause}
                       onChange={() => this.setState({addRedemptionClause: !addRedemptionClause})}/>
                <span className='checkmark'/>
              </label>
              <div className='selectedBoxData'>
                <h5>Add Redemption Clause</h5>
              </div>
            </div>
          </div>
		  
          <div className='box'>
            <p className='blockTitle addTextMB additionalTextSmMT'></p>
            <div className='selectedBox'>
              <label className='checkboxContainer mt-6 ml-6'>
                <input type='checkbox' checked={addConsentClause} value={addConsentClause}
                       onChange={() => this.setState({addConsentClause: !addConsentClause})}/>
                <span className='checkmark'/>
              </label>
              <div className='selectedBoxData'>
                <h5>Add Consent Clause</h5>
              </div>
            </div>
          </div>

          <div className='box'>
            <p className='blockTitle addTextMB additionalTextSmMT'>Tailored Decision</p>
            <TextareaBW label='Tailored Decision'
                        placeholder='Enter tailored decision Here...'
                        name='amendingArticlesTailored'
                        value={amendingArticlesTailored}
                        handleChange={this.handleChange}
						rows={15}
                        smallLabel='smallLabelSizeBW'/>
          </div>

		<div className='text-center'>
            <div className=' btnRegister bottomFixedBtn' onClick={this.saveDecision}>
              <i className='far fa-check-circle circle'/>Save Decision
            </div>
          </div>

        </section>
      </div>
    )
  }

}

export default withRouter(AmendingArticles)
