import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import InputBW from '../components/InputBW'
import {forgotPassword} from '../action'
import Alert from 'react-s-alert'

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loading: false,
	  errors: []
    };
  }

  handleChange = e => {
    const {name, value} = e.target;
	this.setState({[name]: value}, () => this.validate());
  };

  componentDidMount() {
    this.validate();
  }

  validate = () => {
    let errors = {};
  };


  onSubmit = () => {
    this.setState({loading: true});
    let {email} = this.state;
	
	// console.log(this.state);
	
      forgotPassword({email: email}).then(res => {
        if (res.success) {
          this.props.history.replace('/login');
          Alert.success('Successfully sent email');
          this.setState({loading: false});
        } else {
          Alert.error('Error.');
          this.setState({loading: false});
        }
      })
  };

  render() {
    let {errSubmit, errors, loading} = this.state;
    return (
      <section className='inputPart '>
        <div className='box'>
          <InputBW type='email'
                   label='email'
                   placeholder='Enter existing email...'
                   name='email'
				   handleChange={this.handleChange}
				   />
        </div>
        <div className='text-center'>
          <div className='btnRegister' onClick={this.onSubmit}>Send email address {loading && <i className='fa fa-spinner fa-pulse fa-fw'/>}</div>
        </div>
      </section>
    )
  }
}

export default withRouter(ForgotPassword)
