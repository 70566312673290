import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {getDocument, download} from '../action'

class DocumentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      html: '',
      doc: null,
	  planeroller:'Download'
    };
  }

  componentWillMount() {
    this.getDocument();
  }

  getDocument = () => {
    const {id} = this.props.match.params;
    getDocument(id).then(res => {
      this.setState({html: res.html, doc: res.item})
    })
  };

  sign = () => {

  };

  downloadDocument = () => {
    const {id} = this.state.doc;
    download('download', id).then(blob => {
	  this.setState({planeroller:'Downloading...'});
      let link = document.createElement('a');
	  const newBlob = new Blob([blob], { type: 'application/pdf' });
      link.href = window.URL.createObjectURL(newBlob);
      link.target = '_blank';
      link.download = `document_${id}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
	  this.setState({planeroller:'Downloaded.'});
    })
  };

  render() {
    const {html, doc, planeroller} = this.state;
    return (
      <section className='inputPart'>
        <div className='box'>
          <div className='pdfLook pb-5 mb-5'>
            <div dangerouslySetInnerHTML={{__html: html}} style={{color: 'black'}}/>
          </div>
        </div>
        {/*<div className='text-center'>*/}
        {/*<div className='btnRegister bottomFixedBtn' onClick={this.sign}>*/}
        {/*<i className='fab fa-telegram-plane planeIcon'/>Sign*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*doc && doc.state === 'signed' && */}
        <div className='text-center'>
          <div className='btnRegister bottomFixedBtn' onClick={this.downloadDocument}>
            <i className='fab fa-telegram-plane planeIcon'/>{planeroller}
          </div>
        </div>
        {/**/}
      </section>
    )
  }
}

export default withRouter(DocumentView)
