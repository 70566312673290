import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import CircleRadioInput from './../components/CircleRadioInput'
import {get} from '../action'

export default class ShareClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      list: []
    }
  }

  componentWillMount() {
    this.getList();
  }

  getList = () => {
    const {list} = this.props;
    get({model: 'list', action: 'list', body: {type: list}}).then(res => {
      this.setState({list: res.list})
    });
  };

  renderList = (item, i) => {
    const {radioItem, chooseOption, stateName} = this.props;
    const {id, label} = item;
    return <CircleRadioInput key={`s-${id}-${i}`}
                             label={label}
                             name={id}
                             chooseOption={() => chooseOption(stateName, item)}
                             radioItem={radioItem ? radioItem.id : null}/>
  };

  render() {
    const {list} = this.state;
    return (
      <div>
        <div className='radioPadTop'/>
        {list.map(this.renderList)}
        {/*<Link to='#' className='learnMoreLink'>*/}
          {/*<i className='far fa-question-circle'/>Learn more / Oppia lisää*/}
        {/*</Link>*/}
      </div>
    )
  }
}
