import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import iconFile from '../../../assets/img/icons/White/file.png'

const sliderAuthData = [
  {
    title: 'Maintain1 Archives',
    text: 'PocketInc. lets you keep a record of all meetings and decisions made in the company.',
  },
  {
    title: 'Maintain2 Archives',
    text: 'PocketInc. lets you keep a record of all meetings and decisions made in the company.',
  },
  {
    title: 'Maintain3 Archives',
    text: 'PocketInc. lets you keep a record of all meetings and decisions made in the company.',
  }
];

export default class Authorization extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // $('.centerSlider').slick({
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: false,
    //     autoplaySpeed: 2000,
    //     dots: true,
    //     arrows:false,
    // });
  }

  render() {
    return (
      <div className='inputPartFirst authBG'>
        <div className='box'>
          {/*<img src={iconFile} alt='Pocket Inc.' className='filesIcon'/>*/}
          <div className='centerSlider2'>

            {/*{*/}
              {/*sliderAuthData.map((item, i) => {*/}
                {/*return <div key={`s-k-${i}`}>*/}
                  {/*<p className='authStep'>Feature {i + 1} / 3</p>*/}
                  {/*<div className='authSlide'>*/}
                    {/*<h4 className='authStepTitle'>{item.title}</h4>*/}
                    {/*<p className='authStepText'>{item.text}</p>*/}
                  {/*</div>*/}
                {/*</div>*/}
              {/*})*/}
            {/*}*/}

            <p>With PocketInc service you can create, sign, archive, browse and register minutes of all mandatory corporate resolutions made by shareholders and board of directors, for example:
              <br/>
              <ul className="ulFirst">
               <li>annual general meetings</li>
               <li>election of board members</li>
               <li>election of chairman</li>
               <li> issue of shares</li>
               <li>redemption of shares</li>
               <li>procurations</li>
            </ul>
            </p>
            <p> Simply and without need to consult a local lawyer. The service is designed particularly for use by mobile devices. </p>
            <p>The service is now available as a free beta version with limited functionality for Finnish corporations. Please contact admin@pocketinc.fi to send feedback or receive support. </p>
			<p><br/></p>
			<p><i>In Finnish: PocketInc palvelulla luot ja allekirjoitat helposti osakeyhtiön hallituksen ja yhtiökokousten pöytäkirjat tai yksimieliset päätökset. Pöytäkirjat ovat englanninkielisiä, mutta Kaupparekisterin edellyttämät osiot päätöksistä ovat myös suomeksi.</i></p>
          </div>
          <div className='text-center'>
            <Link to='/login' className='btnAutorisation mr10'>Login</Link>
            
			<Link to='/registration' className='btnAutorisation mr10'>Register</Link>
			{/**/}
          </div>
        </div>
      </div>

    )
  }
}
