import React, {Component} from 'react'

export default class InputBW extends Component {
  render() {
    const {type, label, placeholder, name, value, handleChange, errSubmit, warning, doOnTimes, mb = '', mt = '', issueStyle, smallLabel, smallBtnDelete,numberStyle} = this.props;
    return (
      <div>
        <label className={`fieldLabel ${smallLabel ? smallLabel : ''}`}>{label}</label><br/>
        <input
          type={type ? type : 'text'}
          className={`fieldPlace ${issueStyle ? issueStyle : ''} ${mb} ${numberStyle}`}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={handleChange}
        />
        {doOnTimes &&
        <i className={` fas fa-times-circle ${smallBtnDelete ? smallBtnDelete : 'compInfoNamedelete'}`} onClick={() => doOnTimes(name)}/>}
        {errSubmit && warning && <p className={`inputWarning ${mt}`}>{warning}</p>}
      </div>
    )
  }
}
