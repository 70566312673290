import React, {Component} from 'react'

export default class SelectedPerson extends Component {
  render() {
    const {user} = this.props;
    const {name, email} = user;
    return (
      <div>
        <div className='selectedBox'>
          <div className='selectedBoxData'>
            <h5>{name}</h5>
            <p>{email}</p>
          </div>
        </div>
      </div>
    )
  }
}
