import React, {Component} from 'react'
import SliderBox from './SliderBox'

export default class SliderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    window.addEventListener('resize', this.findWidth.bind(this));
    this.findWidth();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.findWidth.bind(this))
  }

  findWidth = () => {
    this.setState({windowW: window.innerWidth});
    $('.listSlider' + this.props.k).slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      dots: true,
      arrows: false,

      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
          }
        }
      ]
    });
  };


  render() {
    let {sliderCompanyData, k} = this.props;
    return (
      <div>
        <div className='companySlider'>
          <div className={`listSlider${k} mb-5`}>
            {sliderCompanyData.map((i, index) => <SliderBox document={i} key={index}/>)}
          </div>
        </div>
      </div>
    )
  }

}
