import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {loadForm, loadState, saveForm} from '../../storage'
import ButtonDropdownB from '../../components/ButtonDropdownB'
import TextareaBW from '../../components/TextareaBW'
import Popup from '../../components/Popup'
import Members from '../../components/Members'
import {get} from '../../action'
import Datepicker from '../../components/Datepicker'

class AnnualGeneralMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: new Date(),
      end: new Date(),
      use_of_profit: '',
      compensation: '',
      auditor: '',
      tailored_decision: '',
      errors: {},
      openPopup: [],
      popUp: {},
      popup: 'shareholders',
      users: [],
      ordinary: [],
      deputy: [],
      decision: ''
    };
    window.scrollTo(0,0);
  }

  componentDidMount() {
    this.validate();
    this.getUserList();
  }

  componentWillMount() {
    const {idx, id} = this.props.match.params;
    const form = loadForm();
    if (form.decisions && form.decisions[idx]) {
      this.setState(form.decisions[idx].content)
    }
  }

  // getUserList = () => {
    // const {company} = loadState();
    // get({model: 'user', action: 'list', body: {company_id: company.id, type: 'board'}}).then(res => {
      // this.setState({users: res.list});
    // })
  // };
  isEmailInObject(email, users=[])
  {
	  // let u=(Object.keys(users)).filter(key=>{
			    // return((users[key]['email']==email))
		  // });
		  const u = users.find(f => f.email==email)
    // console.log(u);
	  return(u);
  }
  
  getUserList = (m = []) => {
    const {company} = loadState();
    let {users}=this.state;
    get({model: 'user', action: 'list', body: {company_id: company.id, type: 'board', type2: 'boarddeputy'}}).then(res => {
	  if (users.length<1)
		  this.setState({users: res.list});
	  if (m.length>0) {
		  if (!users && users.length<1)
			users=[];
		   m.forEach(x=>{
			if (!this.isEmailInObject(x.email, users))
				users.push(x);
		  });
		  this.setState({users: users});
		  // console.log(m);
		  // console.log(users);
	  }
    })
  };
  showPopup = (i, type) => {
    let {openPopup = [], popUp = {}} = this.state;
    popUp.status = !popUp.status;
    popUp.type = type;
    if (i) {
      openPopup[i] = !openPopup[i];
    } else {
      openPopup = [];
    }
    window.scrollTo(0, 0);
    this.setState({openPopup: openPopup, popUp: popUp});


  };

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value}, () => this.validate())
  };

  handleChangeDate = name => date => {
    this.setState({[name]: date});
  };

  validate = () => {
    let errors = {};
    let {} = this.state;
    this.setState({errors: errors})
  };

  clearField = name => {
    this.setState({[name]: ''})
  };

  chooseOption = (fieldName, name) => {
    this.setState({[fieldName]: name});
  };

  saveDecision = () => {
    const {history, match} = this.props;
    const {idx, id,ret} = match.params;
    let {errors} = this.state;
    let countErrs = Object.keys(errors);
    if (countErrs.length === 0) {
      let form = loadForm();
      if (!form.decisions) {
        form.decisions = [{id: id, content: this.state}];
      } else {
        form.decisions[idx] = {id: id, content: this.state};
      }
      saveForm(form);
	  if (ret=='board')
		this.props.history.replace('/meeting/board');
	  else
		this.props.history.replace('/meeting/shareholders');

    } else {
      this.setState({errSubmit: true})
    }
  };

  renderUsers = u => {
    return (
      <div key={u.id} className='selectedBox'>
        <div className='selectedBoxData'>
          <h5>{u.name}</h5>
          <p>{u.email}</p>
        </div>
      </div>
    )
  };

  render() {
    const {
      errors, start, end, use_of_profit, compensation, auditor, tailored_decision, popUp = {},
      openPopup,
      users,
      ordinary,
      deputy
    } = this.state;

    const listProps = {
      searchPlaceholder: 'Search Members',
      addBtnText: 'Add Board Member'
    };

    const popupProps = {
      title: 'Board Members',
      subtitle: 'Hallituksen jäsenet',
    };

    return (
      <div>

      <div className={`shade ${popUp && popUp.status ? 'shadeActive' : ' '}`}/>
      <section className='inputPart pbToSeeBottomMenu'>

        <div className='box'>
          <p className='blockTitle addTextMB'>Financial Year (Adoption of Accounts)</p>
          <div className='smallInputBW'>
            <Datepicker calendarStyle='calendarStylePurple'
                        label='Start'
                        smallLabel='smallLabelSizeBW'
                        chooseDate={this.handleChangeDate('start')}
                        value={new Date(start)}/>
          </div>

          <div className='smallInputBW'>
            <Datepicker calendarStyle='calendarStylePurple'
                        label='End'
                        smallLabel='smallLabelSizeBW'
                        chooseDate={this.handleChangeDate('end')}
                        value={new Date(end)}/>
          </div>
          <p className='blockTitle addTextMB '>Discharge from liability is added by default</p>
        </div>
        <br/>

        <div className='box'>
          <p className='blockTitle addTextMB additionalTextSmMT'>Profit/loss of the Financial Year</p>
          <TextareaBW label='Use of Profit/loss'
                      placeholder='Enter use here...'
                      name='use_of_profit'
                      value={use_of_profit}
                      handleChange={this.handleChange}
                      warning={errors.use_of_profit}
                      smallLabel='smallLabelSizeBW'/>

        </div>

        <div className='box'>
          <p className='blockTitle addTextMB'>Elect members of Board</p>
          {popUp && popUp.status && (popUp.type === 'ordinary')
            ? <Popup title={popupProps.title} subtitle={popupProps.subtitle} popupStatus={popUp.status}
                     closePopup={() => this.showPopup()}
                     doOnTimes={this.clearField}>
              <Members
                {...listProps}
                selected={ordinary}
                selectAction={m => this.setState({ordinary: m})}
                onUpdate={(m) => this.getUserList(m)}
                type={'board'}
                users={users.filter(u => u.type=='board')}
				/>
            </Popup>
            : null}


          <p className='welcomeText addTextMB additionalTextSmMT'>The Ordinary Members</p>
          <ButtonDropdownB
            title='the board of the company'
            subtitle='Select Members'
            doOnClick={() => this.showPopup(0, 'ordinary')}
            arrowActive={openPopup[0]}/>

          {users.map(u => {
            if (ordinary.includes(u.id)) {
              return this.renderUsers(u)
            }
          })}

          {popUp && popUp.status && (popUp.type === 'deputy')
            ? <Popup title={popupProps.title} subtitle={popupProps.subtitle} popupStatus={popUp.status}
                     closePopup={() => this.showPopup()}
                     doOnTimes={this.clearField}>
              <Members
                {...listProps}
                selected={deputy}
                selectAction={m => this.setState({deputy: m})}
                onUpdate={(m) => this.getUserList(m)}
                users={users.filter(u => u.type=='boarddeputy')}
				type='boarddeputy'/>
            </Popup>
            : null}

          <ButtonDropdownB
            title='deputy members of the board'
            subtitle='Select Members'
            doOnClick={() =>  this.showPopup(1, 'deputy')}
            arrowActive={openPopup[1]}/>

          {users.map(u => {
            if (deputy.includes(u.id)) {
              return this.renderUsers(u)
            }
          })}

          <div className=''>
            <TextareaBW label='Compensation'
                        rows={1}
                        placeholder='Compensation for board member...'
                        name='compensation'
                        value={compensation}
                        handleChange={this.handleChange}
                        warning={errors.compensation}
                        smallLabel='smallLabelSizeBW'/>
          </div>
        </div>

        <div className='box'>
			<div className='radioTitle' style={{textAlign: "left", color:'#000'}}>
			<input 	type="checkbox" name='auditorcheckbox'
					onClick={()=>{
						$('#auditordiv').toggle();
						this.setState({auditor:''});
					}}
					/>No Auditor
			</div>
			<div className='' id='auditordiv' style={{display: "block"}}>
				<p className='blockTitle'>Auditor</p><br/>
				<TextareaBW label='Name'
                      rows={1}
                      placeholder='Auditor’s name'
                      name='auditor'
                      value={auditor}
                      handleChange={this.handleChange}
                      warning={errors.auditor}
                      smallLabel='smallLabelSizeBW'/>
				<p className='blockTitle addTextMB'>Auditor will be reimbursed according to the invoice.</p>
			</div>
        </div>

        <div className='box'>
          <p className='welcomeText addTextMB additionalTextSmMT'>Tailored Decision</p>
          <TextareaBW label='Tailored Decision'
                      placeholder='Enter decision Here...'
                      name='tailored_decision'
                      value={tailored_decision}
                      handleChange={this.handleChange}
                      warning={errors.tailored_decision}
                      smallLabel='smallLabelSizeBW'/>
        </div>

        <div className='text-center'>
          <div className=' btnRegister bottomFixedBtn' onClick={this.saveDecision}>
            <i className='far fa-check-circle circle'/>Save Decision
          </div>
        </div>

      </section>
      </div>
    )
  }
}

export default withRouter(AnnualGeneralMeeting)
