import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {openDocument} from '../action'

class OpenDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      html: '',
      doc: null,
      state: -1
    };
  }

  componentDidMount() {
    this.getDocument();
  }

  getDocument = () => {
    const {hash} = this.props.match.params;
    openDocument('pdf', hash).then(res => {
      if (res.success) {
        this.setState({html: res.html, doc: res.doc, state: res.state})
      } else {
        this.props.history.replace('/')
      }
    })
  };

  sign = () => {
    const {id, hash} = this.props.match.params;
    openDocument('sign', hash).then(res => {
      this.setState({state: res.state})
    })
  };

  render() {
    const {html, state} = this.state;
    let disabledStyle = {pointerEvents: 'none', opacity: 0.4};
    let btnTitle = '';

    if (state === -1) {
      btnTitle = 'Click here to sign';
      disabledStyle = {};
    } else {
      btnTitle = 'Succesfully signed. You can now close this window';
      disabledStyle = {pointerEvents: 'none', opacity: 0.4};
    }

    return (
      <section className='inputPart'>
        <div className='box'>
          <div className='pdfLook pb-5 mb-5'>
            <div dangerouslySetInnerHTML={{__html: html}} style={{color: 'black'}}/>
          </div>
        </div>

        <div className='text-center' style={disabledStyle}>
          <div className='btnRegister bottomFixedBtn' onClick={this.sign}>
            <i className='fab fa-telegram-plane planeIcon'/>{btnTitle}
          </div>
        </div>

      </section>
    )
  }
}

export default withRouter(OpenDocument)
