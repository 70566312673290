import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {get} from '../action'
import {loadState} from '../storage'
import moment from 'moment'

export default class SliderBoxDraft extends Component {
   constructor(props) {
    super(props);
    this.state = {}
  }
  onDelete2 = (id) =>{
	  
	  const { onDelete2 } = this.props;
	  
	  // console.log(id);
	  
	  onDelete2(id);
  }
  render() {
	const {document, text} = this.props;
    const {id, created_at, state, type2, type, pending} = document;

    let title ='';
	if ((type=='board') && (type2=='meeting'))
		title+=" Board Meeting";
	if ((type=='board') && (type2!='meeting'))
		title+=" Board's Written Decision";
	if ((type!='board') && (type2=='meeting'))
		title+=" Shareholders\' Meeting";
	if ((type!='board') && (type2!='meeting'))
		title+=" Shareholders\' Unanimous Decision";
	if (type==='confirmation')
		title="Board/Auditor Confirmation for Share Issue";		

    let boardorshareholder ='';
	boardorshareholder= ((type == 'board') ?'/meeting/board':'/meeting/shareholders');
	boardorshareholder= ((type == 'confirmation') ?'/confirmation':boardorshareholder);

	const signstyle={fontSize: 10, color:'black', overflow: 'hidden'}

    return (
      <div key={`key-d-${id}`}>
        <div className='compInfoBlock'>
          <div className='padBox blockHeight'>
            <p className='compInfoBlockDate'>Draft {moment(created_at).format('DD/MM/YYYY HH:mm:ss')}</p>
            <h5 className='compInfoBlockTitle'>{title}</h5>
          </div>
		  <Link to={`/draftdoc/${id}`}
			className='btnCompInfoBlock' style={{borderBottom:'1px solid white'}}>Open document</Link>
		  
            <div className='btnCompInfoBlock' style={{borderBottom:'1px solid white', cursor:'pointer'}} onClick={ ()=>{this.onDelete2(id)} } >
				Delete
            </div>
			<Link to={`${boardorshareholder}/${id}`}
                className='btnCompInfoBlock' style={{borderBottom:'1px solid white'}} >Edit</Link>
		</div>
	  </div>
    )
  }
}
