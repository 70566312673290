import React, {Component} from 'react'

export default class TextareaBW extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {label, name, value, errSubmit, warning, smallLabel, placeholder, handleChange, rows} = this.props;
    return (
      <div>
        <label className={`fieldLabel ${smallLabel ? smallLabel : ''}`}>{label}</label><br/>
        <textarea
          rows={rows || '3'}
          className='fieldPlace'
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={handleChange}>

        </textarea>
        {errSubmit && warning && <p className={`inputWarning`}>{warning}</p>}
      </div>
    )
  }
}
