import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import InputBW from '../../components/InputBW'
import ButtonDropdownB from '../../components/ButtonDropdownB'
import Popup from '../../components/Popup'
import ShareClass from '../../components/ShareClass'
// import Datepicker from '../../components/Datepicker'
import TextareaBW from '../../components/TextareaBW'
import {loadForm, saveForm} from '../../storage'

class AuthorizingBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    }
  }

  componentWillMount() {
    const {idx, id} = this.props.match.params;
    const form = loadForm();
    if (form.decisions && form.decisions[idx]) {
      this.setState(form.decisions[idx].content)
    }
  }

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value}, () => this.validate())
  };

  handleChangeDate = name => date => {
    this.setState({[name]: date});
  };

  componentDidMount() {
    this.validate();
  }

  validate = () => {
    let errors = {};
    let {numberOfShares_1, decision} = this.state;

    if (decision && decision.length>0) {
      this.setState({errors: errors});

      return;
    }

    this.setState({errors: errors});
	
  };

  clearField = name => {
    this.setState({[name]: ''})
  };

  showPopup = (i, type) => {
    let {openPopup = [], popUp = {}} = this.state;
    popUp.status = !popUp.status;
    popUp.type = type;
    if (i) {
      openPopup[i] = !openPopup[i];
    } else {
      openPopup = [];
    }
    window.scrollTo(0, 0);
    this.setState({openPopup: openPopup, popUp: popUp});
  };


  chooseOption = (fieldName, name) => {
    this.setState({[fieldName]: name}, () => this.validate());
  };

  saveDecision = () => {
    const {history, match} = this.props;
    const {idx, id,ret} = match.params;
    let {errors} = this.state;

    // console.log('errors: ', errors);
    // console.log('id: ', id);

    let countErrs = Object.keys(errors);
    if (countErrs.length === 0) {
      let form = loadForm();
      if (!form.decisions) {
        form.decisions = [{id: id, content: this.state}];
      } else {
        form.decisions[idx] = {id: id, content: this.state};
      }
      saveForm(form);
	  if (ret=='board')
		this.props.history.replace('/meeting/board');
	  else
		this.props.history.replace('/meeting/shareholders');

    } else {
      this.setState({errSubmit: true})
    }
  };

  render() {
    let {
      openPopup = [],
      errSubmit,
      errors,
      popUp,

      type2='issue',
      shareClass_1,
      numberOfShares_1,

      decision

    } = this.state;
    return (
      <div>
		<p className='addText'>Select Type of Authorization</p>
        <div className={`shade ${popUp && popUp.status ? 'shadeActive' : ' '}`}/>
        <section className='inputPart pbToSeeBottomMenu'>

		<div className='metingTypeBlock'>
		  <div className={`metingType  ${type2 === 'issue' ? 'metingTypeSelected' : 'pointer'}`}
			   onClick={() => this.setState({type2: 'issue'}, this.validate)}>Issue Shares
		  </div>
		  <div className={`metingType  ${type2 === 'redeem' ? 'metingTypeSelected' : 'pointer'}`}
			   onClick={() => {}}>Redeem Shares
		  </div>
		  <div className={`metingType  ${type2 === 'options' ? 'metingTypeSelected' : 'pointer'}`}
			   onClick={() => {} }>Issue of Options
		  </div>
		</div>
		{type2 === 'issue' && <div>
		
          {popUp && popUp.status && (popUp.type === 'issue')
            ? <Popup title='Share Class' subtitle='Osakelaji'
                     popupStatus={popUp.status}
                     closePopup={() => this.showPopup()}
                     doOnTimes={this.clearField}
                     popupSize='popUpBoxWidth'>
              <ShareClass radioItem={shareClass_1}
                          list='shareclass'
                          type='shareClass_1'
                          chooseOption={this.chooseOption}
                          stateName='shareClass_1'/>
            </Popup>
            : null
          }

          <div className='box'>
            <p className='blockTitle addTextMB '></p>
            <div className='smallInputBW'>
              <InputBW type='number'
                       label='Max number of shares'
                       name='numberOfShares_1'
                       placeholder='0'
                       value={numberOfShares_1}
                       handleChange={this.handleChange}
                       errSubmit={errSubmit}
                       warning={errors.numberOfShares_1}
                       doOnTimes={this.clearField}
                       issueStyle='issueStyle'
                       numberStyle='numberField'
                       smallLabel='smallLabelSizeBW'
                       smallBtnDelete='deleteONSmallINput'/>
            </div>

            <div className='smallDropdownB'>
              <ButtonDropdownB title='Share class'
                               subtitle={shareClass_1 && shareClass_1.label || 'Select Class'}
                               doOnClick={() => this.showPopup(1, 'issue')}
                               arrowActive={openPopup[1]}
                               smDropdownLabel='smallLabelSizeDd'
                               errSubmit={errSubmit}
                               warning={errors.shareClass_1}/>
            </div>
          </div>

          <div className='box'>
            <p className='blockTitle addTextMB additionalTextSmMT'>Tailored Decision</p>
            <TextareaBW label='Tailored Decision'
                        placeholder='Enter decision Here...'
                        name='decision'
                        value={decision}
                        handleChange={this.handleChange}
              // errSubmit={errSubmit}
              // warning={errors.decision}
                        smallLabel='smallLabelSizeBW'/>
          </div>
    </div>
		}
          <div className='text-center'>
            <div className=' btnRegister bottomFixedBtn' onClick={this.saveDecision}>
              <i className='far fa-check-circle circle'/>Save Decision
            </div>
          </div>

        </section>
      </div>
    )
  }

}

export default withRouter(AuthorizingBoard)
