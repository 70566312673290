import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'
import mainLogo from '../../assets/img/icons/Logo/Logo-onDark.png'
import {loadState, clearForm, clearState} from '../storage'
import {auth} from '../action'

const bigHeaderTitle = {
  auth: 'Company in your Pocket.',
  log: 'Login to Your Account',
  reg: 'Create a New Account'
};

const smallHeaderTitle = {
  meeting: {
    shareholders: 'New Shareholders\' Meetings',
    board: 'New Board Meeting'
  },
  shareholders: 'Shareholders',
  board: 'Board of Directors',
  company: 'Company',
  corporate: 'Corporate Actions',
  corporate1: 'Notice To Shareholders` Meeting',
  confirmation: 'Board/Auditor Confirmation',
  settings: 'Settings',
  settingspassword: 'Settings password',
  manageuserrights: 'Manage User Rights',
  help: 'Help',
  addserviceuser: 'Add ServiceUser',
  companyInfo: 'Company Information',
  changeCompany: 'Select company',
  authorizationboard: 'Select company',
  amendingarticles: 'Amending The Articles',
  decision: {
    issue: 'Directed Issue of Shares',
  },
};

const smallHeadersubTitle = {
  meeting: {
    shareholders: 'Yhtiökokous',
    board: 'Hallituksen kokous',
  },
  shareholders: 'Yhtiökokoukset',
  board: 'Hallitus',
  company: 'Overview',
  corporate: 'Yhtiön muut toimet',
  corporate1: 'Kutsu yhtiökokoukseen',
  confirmation: 'Hallituksen/tilintarkastajan vakuutus',
  settings: 'Asetukset',
  settingspassword: 'Asetukset - salasana',
  manageuserrights: 'Hallinoi käyttöoikeuksia',
  help: 'Apua',
  addserviceuser: 'Lisää serviceuser',
  companyInfo: 'Yrityksen Tiedot',
  changeCompany: 'Select company',
  authorizationboard: 'Hallituksen valtuuttaminen',
  amendingarticles: 'Yhtiöjärjestyksen muutos',
  decision: {
    issue: 'Suunnattu Osakeanti',
  },
};


class Header extends Component {

  goBack = cancel => {
    if (cancel) {
      clearForm();
    }
    history.back();
  };

  logout = () => {
    const state = loadState();
    auth({action: 'logout', body: {id: state.id}}).then(res => {
      if (res.success) {
        clearState();
		sessionStorage.clear();
        this.props.history.push('/');
      }
    })
  };
 
  render() {
    let company = null;
    let positionZero = [];
    const state = loadState();

    const {pathname, search} = this.props.location;

	// console.log(pathname);
	
    const logged_in = state && state.auth_token
    if (state && state.company) {
      company = state.company
    }

    if ((pathname === '/' && !logged_in) || pathname === '/registration' || pathname === '/login') {

      let isAuthPage = pathname === '/authorization';
      let headerBigTitle = '';

      switch (pathname) {
        case '/':
          headerBigTitle = bigHeaderTitle.auth;
          break;
        case '/login':
          headerBigTitle = bigHeaderTitle.log;
          break;
        case '/registration':
          headerBigTitle = bigHeaderTitle.reg;
          break;
      }

      return (
        <section className='blueBGChapter'>
          <div className='box padB15'>
            <div className='row margH15'>

            </div>
            <img src={mainLogo} alt='Pocket Inc.' className='leftLogo'/>
            <h1 className='authPhrase'>{headerBigTitle}</h1>
          </div>
        </section>

      )
    } else {
      let headerSmallTitle = '';
      let subHeaderSmallTitle = '';
      let backBtn, settngBtn, changeCompanyBtn, backBtn2, cancelBtn, backLinkBtn, linkTo, changeTypeBtn;
      {
        switch (pathname) {
          case '/':
            headerSmallTitle = company && company.name || smallHeaderTitle.company;
            subHeaderSmallTitle = smallHeadersubTitle.company;
            changeCompanyBtn = true;
            settngBtn = true;
            break;
          case '/overview':
            headerSmallTitle = company.name || smallHeaderTitle.company;
            subHeaderSmallTitle = smallHeadersubTitle.company;
            changeCompanyBtn = true;
            settngBtn = true;
            break;

          case '/corporate':
            headerSmallTitle = smallHeaderTitle.corporate;
            subHeaderSmallTitle = smallHeadersubTitle.corporate;
            changeCompanyBtn = false;
            settngBtn = false;
            break;

          case '/confirmation':
            headerSmallTitle = smallHeaderTitle.confirmation;
            subHeaderSmallTitle = smallHeadersubTitle.confirmation;
            changeCompanyBtn = false;
            settngBtn = false;
            cancelBtn = true;
            break;

          case '/corporate/1':
            headerSmallTitle = smallHeaderTitle.corporate1;
            subHeaderSmallTitle = smallHeadersubTitle.corporate1;
            changeCompanyBtn = false;
            settngBtn = false;
            cancelBtn = true;
            break;

          case '/settings/help':
            headerSmallTitle = smallHeaderTitle.settings;
            subHeaderSmallTitle = smallHeadersubTitle.settings;
            backBtn = true;
            cancelBtn = false;
            break;
          case '/settings/':
          case '/settings':
            headerSmallTitle = smallHeaderTitle.settings;
            subHeaderSmallTitle = smallHeadersubTitle.settings;
            backBtn = true;
            break;

          case '/settings/password':
            headerSmallTitle = smallHeaderTitle.settingspassword;
            subHeaderSmallTitle = smallHeadersubTitle.settingspassword;
            backBtn = true;
			positionZero={top:0};
            break;
          case '/settings/manageuserrights':
            headerSmallTitle = smallHeaderTitle.manageuserrights;
            subHeaderSmallTitle = smallHeadersubTitle.manageuserrights;
            backBtn = true;
            break;
          case '/settings/addserviceuser':
            headerSmallTitle = smallHeaderTitle.addserviceuser;
            subHeaderSmallTitle = smallHeadersubTitle.addserviceuser;
            backBtn = true;
            break;

          case '/company/info':
            headerSmallTitle = smallHeaderTitle.companyInfo;
            subHeaderSmallTitle = smallHeadersubTitle.companyInfo;
            backBtn2 = true;
            break;

          case '/company/info/new':
            headerSmallTitle = smallHeaderTitle.companyInfo;
            subHeaderSmallTitle = smallHeadersubTitle.companyInfo;
            backBtn2 = true;
            break;

          case '/select/company':
            headerSmallTitle = smallHeaderTitle.changeCompany;
            subHeaderSmallTitle = smallHeadersubTitle.changeCompany;
            backBtn2 = false;
            break;

          case '/shareholders':
            headerSmallTitle = smallHeaderTitle.shareholders;
            subHeaderSmallTitle = smallHeadersubTitle.shareholders;
            break;

          case '/board':
            headerSmallTitle = smallHeaderTitle.board;
            subHeaderSmallTitle = smallHeadersubTitle.board;
            break;
          case '/forgotPassword':
            headerSmallTitle = 'Forgot password';
            subHeaderSmallTitle = 'Password reset';
			  changeTypeBtn = false;
			  settngBtn = false;
			  backBtn = true;
            break;
          case '/tok':
            headerSmallTitle = 'Reset password';
            subHeaderSmallTitle = 'Enter new password';
			  changeTypeBtn = false;
			  settngBtn = false;
			  backBtn = false;
            break;

          case '/meeting/shareholders':
            headerSmallTitle = smallHeaderTitle.meeting.shareholders;
            subHeaderSmallTitle = smallHeadersubTitle.meeting.shareholders;
            if (search === '?preview') {
              backBtn = true;
            } else {
              cancelBtn = true;
            }
            break;
          case '/meeting/board':
            headerSmallTitle = smallHeaderTitle.meeting.board;
            subHeaderSmallTitle = smallHeadersubTitle.meeting.board;
            if (search === '?preview') {
              backBtn = true;
            } else {
              cancelBtn = true;
            }
            break;
        }

        if (pathname.includes('/confirmation')) {
            headerSmallTitle = smallHeaderTitle.confirmation;
            subHeaderSmallTitle = smallHeadersubTitle.confirmation;
            changeCompanyBtn = false;
            settngBtn = false;
            cancelBtn = true;
        }

        if (pathname.includes('/meeting/shareholders')) {
            headerSmallTitle = smallHeaderTitle.shareholders;
            subHeaderSmallTitle = smallHeadersubTitle.shareholders;
            backBtn = true;
            cancelBtn = false;
		}

        if (pathname.includes('/meeting/board')) {
            headerSmallTitle = smallHeaderTitle.board;
            subHeaderSmallTitle = smallHeadersubTitle.board;
            backBtn = true;
            cancelBtn = false;
		}
			
        if (pathname.includes('/decision/issue')) {
          const type = pathname.split('/')[4];
          switch (parseInt(type)) {
            case 1:
              headerSmallTitle = 'Directed Issue of Shares';
              subHeaderSmallTitle = 'Suunnattu Osakeanti';
              break;

            case 2:
              headerSmallTitle = 'Directed Acquisition of Shares';
              subHeaderSmallTitle = 'Suunnattu Osakkeiden Hankkiminen';
              break;

            case 3:
              headerSmallTitle = 'Election of Chairman';
              subHeaderSmallTitle = 'Hallituksen puheenjohtajan valinta';
              break;

            case 4:
              headerSmallTitle = `Convening Shareholders’ Meeting`;
              subHeaderSmallTitle = 'Päätös yhtiökokouksen pitämisestä';
              break;

            case 5:
              headerSmallTitle = 'Other Decision';
              subHeaderSmallTitle = 'Muu päätös';
              break;
			  
            case 6:
              headerSmallTitle = 'Authorization of Board';
              subHeaderSmallTitle = 'Hallituksen Valtuuttaminen';
              break;

            case 7:
              headerSmallTitle = 'Election of Board Members';
              subHeaderSmallTitle = 'Hallituksen Jasenten Valinta';
              break;

            case 8:
              headerSmallTitle = 'Amending The Articles';
              subHeaderSmallTitle = 'Yhtiöjärjestyksen muutos';
              break;

            case 9:
              headerSmallTitle = 'Annual General Meeting';
              subHeaderSmallTitle = 'Varsinainen yhtiökokous';
              break;

            case 14:
              headerSmallTitle = 'Procuration';
              subHeaderSmallTitle = 'Prokkura';
              break;
          }

          changeTypeBtn = false;
          settngBtn = false;
          backBtn = true;
          // backLinkBtn = 'Board Meeting';
          // linkTo = '/meeting/board';
        }

        if (pathname.includes('/document/')) {
          headerSmallTitle = 'Document view';
          subHeaderSmallTitle = '';
          changeTypeBtn = false;
          settngBtn = false;
          backBtn = true;
        }
		
        if (pathname.includes('/draftdoc/')) {
          headerSmallTitle = 'Draft Document view';
          subHeaderSmallTitle = '';
          changeTypeBtn = false;
          settngBtn = false;
          backBtn = true;
        }

        return (
          <div>
            <section className='blueBGsmChapter' style={positionZero}>
              <div className='box padB15'>
                <div className='row margH15'>
					<div className='btnLogout' style={{maxHeight:'29px',paddingTop:'4px'}} onClick={this.logout}>Logout</div>
                  {backLinkBtn && <Link to={linkTo}>
                    <div className='leftCorner'><i className='fas fa-angle-left'/>
                      {backLinkBtn}
                    </div>
                  </Link>}

                  {backBtn && <div className='leftCorner' onClick={() => this.goBack()}>
                    <i className='fas fa-angle-left'/>Back
                  </div>}

                  {cancelBtn && <div className='leftCorner' onClick={() => this.goBack(true)}>
                    <i className='fas fa-times'/>Cancel
                  </div>}

                  {changeCompanyBtn && <div>
                    <Link to='/select/company' className='leftCorner'>
                      <i className='fas fa-exchange-alt'/>Select company
                    </Link>
                  </div>}

                  {changeTypeBtn && <div>
                    <a href='#' className='rightCorner'>Change Type<i className='fas fa-exchange-alt'/></a>
                  </div>}

                  {settngBtn && <Link to='/settings' className='rightCorner' style={{marginRight:'15px'}} >
                    Settings<i className='fas fa-sliders-h'/>
                  </Link>}

                  {backBtn2 &&
                  <div className='leftCorner' onClick={() => this.goBack()}>
                    <i className='fas fa-angle-left'/> Back
                  </div>}
				  
                </div>

                <h1 className='titleMain'>{headerSmallTitle}</h1>
                <h3 className='subtitleMain'>{subHeaderSmallTitle}</h3>

              </div>

            </section>
          </div>
        )
      }

    }
  }
}

export default withRouter(Header)
