import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import InputBW from '../components/InputBW'
import {auth, get} from '../action'
import {loadState, saveState} from '../storage'
import Alert from 'react-s-alert'

class SettingsPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      oldpassword: '',
      password: '',
      errors: {},
      loading: false
    };
  }

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value}, () => this.validate())
  };

  componentDidMount() {
    this.validate();
  }

  validate = () => {
    let errors = {};
    let {email, password, oldpassword} = this.state;
    if (!password || password.length < 8) {
      errors.password = 'Password too short';
    }
    if (!oldpassword || oldpassword.length < 8) {
      errors.password = 'Password too short';
    }
    this.setState({errors: errors})
  };


  onSubmit = () => {
	const {auth_token,email} = loadState();
    this.setState({loading: true});
    let {errors, password, oldpassword} = this.state;
    let countErrs = Object.keys(errors);
    if (countErrs.length === 0) {
      get({action: 'password', model:'user', body: {email: email, password: password, oldpassword: oldpassword}}).then(res => {
        if (res.success) {
          this.props.history.replace('/settings');
          Alert.success('Successfully changed password');
          this.setState({loading: false});
        } else {
          Alert.error('Error.');
          this.setState({loading: false});
        }
      })
    } else {
      this.setState({errSubmit: true, loading: false})
    }
  };

  render() {
    let {email, password, oldpassword, errSubmit, errors, loading} = this.state;
    return (
      <section className='inputPartFirst paddingTop'>
		<div className='box'>
          <InputBW type='password'
                   label='Current Password'
                   placeholder='Enter current Password...'
                   name='oldpassword'
                   value={oldpassword}
                   errSubmit={errSubmit}
                   warning={errors.password}
                   handleChange={this.handleChange}/>
        </div>
        <div className='box'>
          <InputBW type='password'
                   label='Password'
                   placeholder='Enter New Password...'
                   name='password'
                   value={password}
                   errSubmit={errSubmit}
                   warning={errors.password}
                   handleChange={this.handleChange}/>
        </div>
        <div className='text-center'>
          <div className='btnRegister' onClick={this.onSubmit}>Set Password {loading && <i className='fa fa-spinner fa-pulse fa-fw'/>}</div>
        </div>
      </section>
    )
  }
}

export default withRouter(SettingsPassword)
