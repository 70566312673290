import React, {Component} from 'react'
import {get} from '../action'
import {loadState} from '../storage'

export class ResendButtonO extends Component {
	resendemail(dd, t, company){
		if (confirm('Really resend emails (to '+t+')?'))
		{
			get({model: 'meeting', action: 'resend', body: {company_id: company.id, id: dd}}).then(res => {
				alert('Sent.');
			});
		}
	}
	
	render() {
		const {ddatax} = this.props;
		const {len} = this.props;
		const {company} = loadState();
		return (
			<button className="btnResendEmails" type='button' onClick={() => this.resendemail(ddatax, len, company)}>
				Resend Emails
			</button>
			
		)
  }
}
