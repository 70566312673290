import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'

import Popup from '../../components/Popup'
import TextareaBW from '../../components/TextareaBW'
import ButtonDropdownB from '../../components/ButtonDropdownB'

import {loadForm, loadState, saveForm} from '../../storage'
import Members from '../../components/Members'
import {get} from '../../action'

class ElectionOfTheChairman extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      openPopup: [],
      popUp: {},
      popup: 'board',
      users: [],
      members: [],
      chairman: [],
      decision: ''
    };
    window.scrollTo(0,0);
  }

  componentWillMount() {
    const {idx, id,ret} = this.props.match.params;
    const form = loadForm();
    if (form.decisions && form.decisions[idx]) {
      this.setState(form.decisions[idx].content)
    }
  }

  componentDidMount() {
    this.validate();
    this.getUserList();
  }

  getUserList = () => {
    const {company} = loadState();
    get({model: 'user', action: 'list', body: {company_id: company.id, type: 'board'}}).then(res => {
      this.setState({users: res.list});
	  let {chairman} = this.state;
	  if (chairman==0)
		this.setState({chairman:res.chairman});
		this.setState({chairman2:res.chairman});
    })
  };

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value}, () => this.validate())
  };

  validate = () => {
    let errors = {};
    let {decision} = this.state;
    this.setState({errors: errors})
  };

  clearField = name => {
    this.setState({[name]: ''})
  };

  showPopup = (i, type) => {
    let {openPopup = [], popUp = {}} = this.state;
    popUp.status = !popUp.status;
    popUp.type = type;
    if (i) {
      openPopup[i] = !openPopup[i];
    } else {
      openPopup = [];
    }
    this.setState({openPopup: openPopup, popUp: popUp});
  };


  chooseOption = (fieldName, name) => {
    this.setState({[fieldName]: name});
  };

  selectBoardmember = member => {
    this.setState({chairman: member});
    this.setState({chairman2: member})
  };

  saveDecision = () => {
    const {history, match} = this.props;
    const {idx, id} = match.params;
    let {errors} = this.state;
    let countErrs = Object.keys(errors);
    if (countErrs.length === 0) {
      let form = loadForm();
      if (!form.decisions) {
        form.decisions = [{id: id, content: this.state}];
      } else {
        form.decisions[idx] = {id: id, content: this.state};
      }
      saveForm(form);
      // history.goBack();
	  this.props.history.replace('/meeting/board');
    } else {
      this.setState({errSubmit: true})
    }
  };

  renderUsers = u => {
    return (
      <div key={u.id} className='selectedBox'>
        <div className='selectedBoxData'>
          <h5>{u.name}</h5>
          <p>{u.email}</p>
        </div>
      </div>
    )
  };

  render() {
    let {
      popUp = {},
      openPopup,
      errSubmit,
      errors,
      users,
      members,
      chairman,
      decision
    } = this.state;

    const listProps = {
      searchPlaceholder: 'Search Members (Total 10)',
      addBtnText: 'Add Board Member'
    };

    const popupProps = {
      title: 'Board Members',
      subtitle: 'Hallituksen jäsenet',
    };

    return (
      <div>
        <div className={`shade ${popUp && popUp.status ? 'shadeActive' : ' '}`}/>
        <section className='inputPart pbToSeeBottomMenu'>

          {popUp && popUp.status && (popUp.type === 'shareholders' || popUp.type === 'board')
            ? <Popup title={popupProps.title} subtitle={popupProps.subtitle} popupStatus={popUp.status}
                     closePopup={() => this.showPopup()}
                     doOnTimes={this.clearField}>
              <Members
                {...listProps}
                selected={chairman}
                selectAction={this.selectBoardmember}
                onUpdate={() => this.getUserList()}
                members={users}
                users={users}
				type='board'
              />
            </Popup>
            : null}

          <div className='box'>
            <p className='welcomeText addTextMB additionalTextSmMT'>Chairman of the Board of Directors</p>
            <ButtonDropdownB
              title='name of the member'
              subtitle='Select Member of the Board'
              doOnClick={() => this.showPopup(0, 'board')}
              arrowActive={openPopup[0]}/>
          </div>

          <div className='box'>
            {users.map(u => {
              if (chairman.includes(u.id)) {
                return this.renderUsers(u)
              }
            })}
          </div>

          <div className='box'>
            <p className='welcomeText addTextMB additionalTextSmMT'>Tailored Decision</p>
            <TextareaBW label='Tailored Decision'
                        placeholder='Enter decision Here...'
                        name='decision'
                        value={decision}
                        handleChange={this.handleChange}
                        errSubmit={errSubmit}
                        warning={errors.decision}
                        smallLabel='smallLabelSizeBW'/>
          </div>

          <div className='text-center'>
            <div className=' btnRegister bottomFixedBtn' onClick={this.saveDecision}>
              <i className='far fa-check-circle circle'/>Save Decision
            </div>
          </div>

        </section>
      </div>
    )
  }

}

export default withRouter(ElectionOfTheChairman)
