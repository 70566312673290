import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {ResendButtonO} from './ResendButtonO'
import moment from 'moment'

export default class SliderBox extends Component {
  render() {
    const {document, text} = this.props;
    const {id, date, state, type2, type, pending} = document;
	let psigned=[], punsigned=[], punsigned2=[];
	if (pending && pending.length>0)
	{
		psigned=pending.filter(el=>el.state!==-1).map(el=>'x '+el['email']+' ');
		punsigned2=pending.filter(el=>el.state===-1).map(el=>el['email']);
		punsigned=pending.filter(el=>el.state==-1).map(el=>'('+el['email']+') ');
		// let pnames3=pnames.map(el=>el['userId']);
	}

    let title ='';
    if (type=='notice') title='Notice to shareholders\' meeting'; else {
        // title= ((type === 'board') ?'Board':'Shareholders\'')+' '+((type2 === 'meeting') ? 'Meeting' : 'unanimous decision');
		if ((type==='board') && (type2==='meeting'))
			title="Board Meeting";
		if ((type==='board') && (type2!=='meeting'))
			title="Board's Written Decision";
		if ((type!=='board') && (type2==='meeting'))
			title="Shareholders\' Meeting";
		if ((type!=='board') && (type2!=='meeting'))
			title="Shareholders\' Unanimous Decision";
		if (type==='confirmation')
			title="Board/Auditor Confirmation for Share Issue";		
    }

	const signstyle={fontSize: 10, color:'green', overflow: 'hidden' }
	const unsignedstyle={fontSize: 10, color:'red', overflow: 'hidden' }

    return (
      <div key={`key-d-${id}`}>
        <div className='compInfoBlock'>
          <div className='padBox blockHeight'>
            <p className='compInfoBlockDate'>{moment(date).format('DD/MM/YYYY')}</p>
            <h5 className='compInfoBlockTitle'>{title}</h5>
				{/*<p className='compInfoBlockText'>{text}</p> */}
          </div>
          <Link to={`/document/${id}`}
                className='btnCompInfoBlock'>{state === 'pending' ? 'Review & Sign' : 'Open Document'}</Link>
		</div>
      		<div>
			<p className='' style={signstyle}>{psigned}</p>
			{(punsigned2.length>0) && <ResendButtonO ddatax={id} len={punsigned2.length}></ResendButtonO>}
			<p className='' style={unsignedstyle}>{punsigned}</p>
			</div>
	  </div>
    )
  }
}
