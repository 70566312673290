import React, {Component} from 'react'

export default class DocumentView extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const {html} = this.props;
    return (
      <section className='inputPart'>
        <div className='box'>
          <div className='pdfLook pb-5 mb-5'>
            <div dangerouslySetInnerHTML={{__html: html}} style={{color: 'black'}}/>
          </div>
        </div>
      </section>
    )
  }
}
