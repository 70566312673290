import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'

class ButtonDropdownW extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {style, title, subtitle, count, doOnClick, statusActive, redirectTo, titleCss, preCountText, rightBoxCss} = this.props;
    return (
      <div style={style}>
        <button type='button' className='companyItemRow' onClick={doOnClick}>
          <div className='ddText'>
            <p className={`compBlockTitle ${titleCss ? titleCss : ''}`}>{title}</p>
            {subtitle && <p className='compBlockSubTitle'>{subtitle}</p>}
          </div>
          <div className={`ddSymbols ${rightBoxCss}`}>
            {preCountText && <span className='companyPreCountText'>{preCountText}</span>}
            <span className='companyNum'> {count}</span>
            {statusActive ? <i className='fas fa-angle-up iconAngU'/> : <i className='fas fa-angle-right iconAngR'/>}
          </div>
        </button>
      </div>
    )
  }
}

export default withRouter(ButtonDropdownW)
