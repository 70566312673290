import React from 'react'
import ReactDOM from 'react-dom'
import {HashRouter} from 'react-router-dom'

import Router from './router'

const root = document.getElementById('root');
const App = () => <HashRouter><Router/></HashRouter>;

ReactDOM.render(<App/>, root);

if (module.hot) {
  module.hot.accept();
}
